import { ActionType, useCreateMaterialContext } from '../context/CreateMaterialContext';

import { Stack } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { TrblNumberInput } from '@/components/Shared/NumberInput';
import { Text } from '@/components/Shared/Text';
import { TrblInfoIcon } from '@/components/Icons';
import { MAX_VALUE, MIN_VALUE } from '@/components/MaterialDetailsPopup/constants';

import { FREQUENCY_RANGE_FULL_OCTAVE, FULL_OCTAVES, INITIAL_VALUES_FULL_OCTAVE } from '../constants';

import { Frequency, SimpleData } from '../types';
import { FitMaterial } from '@/types';

import styles from '../styles.module.scss';

export const SimpleInputTable = ({
  editable,
  data,
  materialFit,
  onlyResults = false,
  fittedAbsorptionCoefficients,
  resultTitle = 'Result',
}: {
  editable: boolean;
  data?: SimpleData;
  materialFit?: FitMaterial | null;
  onlyResults?: boolean;
  fittedAbsorptionCoefficients?: number[];
  resultTitle?: string;
}) => {
  const { dispatch } = useCreateMaterialContext();

  const handleOnBlur = (value: number | undefined, freq: Frequency) => {
    const newTargetCoefficients = { ...data };
    if (value !== undefined) {
      newTargetCoefficients[freq] = value;
    } else {
      newTargetCoefficients[freq] = INITIAL_VALUES_FULL_OCTAVE[freq];
    }
    if (editable) {
      dispatch({
        type: ActionType.SET_TARGET_COEFFICIENTS,
        fullOctaveData: newTargetCoefficients,
      });
    }
  };

  const handleOnChange = (value: number | undefined, freq: Frequency) => {
    const newTargetCoefficients = { ...data };
    newTargetCoefficients[freq] = value;
    if (editable) {
      dispatch({
        type: ActionType.SET_TARGET_COEFFICIENTS,
        fullOctaveData: newTargetCoefficients,
      });
    }
  };

  const fittedStyling = (index: number, freq: number): React.CSSProperties | undefined => {
    let color: string | undefined;

    if (materialFit?.material && data && editable) {
      const difference = Math.abs(materialFit.material.FittedAbsorptionCoefficients[index] - data[freq]!);
      if (difference >= 0.05) {
        color = '#f58a69';
      } else if (difference >= 0.02) {
        color = '#fae387';
      }
    }

    return {
      color,
    };
  };

  return (
    <table className={styles.material_input_table}>
      <tbody>
        <tr>
          <th className={styles.first_child}>
            <Text type="semibold-11px" color="#999999">
              Frequency (Hz)
            </Text>
          </th>
          {FREQUENCY_RANGE_FULL_OCTAVE.map((freq: string) => (
            <th key={freq} className={styles.cell_center}>
              {freq}
            </th>
          ))}
        </tr>
        {data && !onlyResults && (
          <tr>
            <td className={`${styles.position_relative} ${styles.first_child}`}>
              <TrblTooltip title="Target values for the random incidence absorption coefficient.">
                <Stack flexDirection="row">
                  <Text type="semibold-11px">Target</Text>
                  <span className={styles.info_icon}>
                    <TrblInfoIcon width="11" height="11" />
                  </span>
                </Stack>
              </TrblTooltip>
            </td>
            {FULL_OCTAVES.map((freq, index) => (
              <td key={freq + index}>
                <TrblNumberInput
                  toFixed={false}
                  center
                  value={data[freq]}
                  step={MIN_VALUE}
                  min={MIN_VALUE}
                  disabled={!editable}
                  max={MAX_VALUE}
                  onChange={(value) => handleOnChange(value, freq)}
                  onBlur={(value) => handleOnBlur(value, freq)}
                  alignment="center"
                  style={{ width: 60, margin: '0 auto', justifyContent: 'center' }}
                />
              </td>
            ))}
          </tr>
        )}
        <tr>
          <td className={`${styles.position_relative} ${styles.first_child}`}>
            <TrblTooltip title="The result of Treble’s material conversion engine showing the resulting random incidence absorption coefficients based on the estimated surface impedance of the material.">
              <Stack flexDirection="row">
                <Text type="semibold-11px">{resultTitle}</Text>
                <span className={styles.info_icon}>
                  <TrblInfoIcon width="11" height="11" />
                </span>
              </Stack>
            </TrblTooltip>
          </td>
          {FULL_OCTAVES.map((freq, index) => (
            <td key={freq + index} className={styles['cell-center']}>
              <Text numberFontStyleEnabled type="regular-11px" style={fittedStyling(index, freq)}>
                {fittedAbsorptionCoefficients && fittedAbsorptionCoefficients.length > 0
                  ? fittedAbsorptionCoefficients[index]
                  : '--'}
              </Text>
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};
