import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { TrblFileIcon } from '@/components/Icons';
import { TrblTruncate } from '@/components/SoundLibrary/TrblTruncate';

import styles from './styles.module.scss';

export type FileUploadProps = {
  accept: string;
  acceptText: string;
  label?: string;
  idleIcon?: ReactNode;
  successIcon?: ReactNode;
  acceptIcon?: ReactNode;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDropFile: (event: React.DragEvent<HTMLElement>) => void;
  disabled?: boolean;
  backgroundImage?: string;
  newFilename?: string;
  maxSizeInMB?: number;
  small?: boolean;
  errorMessage?: null | string;
  extraMessage?: ReactNode;
  shouldReset?: boolean;
};

export const FileUpload: FC<FileUploadProps> = ({
  accept,
  acceptText,
  label = 'Drop file here to import',
  idleIcon,
  successIcon,
  acceptIcon,
  onChange,
  onDropFile,
  disabled,
  backgroundImage,
  newFilename,
  maxSizeInMB,
  small = false,
  errorMessage,
  extraMessage,
  shouldReset,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState<string | null>(null);

  useEffect(() => {
    if (shouldReset) {
      clearFileInput();
    }
  }, [shouldReset]);

  const stopDefaults = (event: React.DragEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files?.[0]) {
      const newFile = event.target.files[0];
      if (!fileValidation(newFile)) return;
      setFileName(newFile.name);
    }
    onChange(event);
  };

  const clearFileInput = () => {
    setFileName(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const dragEvents = {
    onDragOver: stopDefaults,
    onDrop: (event: React.DragEvent<HTMLElement>) => {
      stopDefaults(event);
      if (event.dataTransfer.files[0]) {
        const newFile = event.dataTransfer.files[0];
        if (!fileValidation(newFile)) return;
        setFileName(newFile.name);
      }
      onDropFile(event);
    },
  };

  const fileValidation = (file: File) => {
    const fileType = ('.' + file.name.split('.').pop()).toLowerCase();

    if (!accept.includes(fileType)) {
      toast.error('Invalid file type');
      return false;
      // Formula to convert MB to bytes is: MB * 1048576
    } else if (maxSizeInMB !== undefined && file.size > maxSizeInMB * 1048576) {
      toast.error('Max file size is ' + maxSizeInMB + 'MB');
      return false;
    } else return true;
  };

  useEffect(() => {
    if (newFilename) {
      setFileName(newFilename);
    }
  }, [newFilename]);

  return (
    <div className={`${styles.file_upload_container} ${small ? styles.small : ''} ${disabled ? styles.disabled : ''}`}>
      <div
        className={`${styles.file_upload_content} ${
          errorMessage && errorMessage.length > 0 ? styles.upload_error : ''
        }`}
        {...dragEvents}>
        {backgroundImage && <img src={backgroundImage} className={styles.back_img} alt="file upload image" />}
        <input
          ref={fileInputRef}
          onChange={handleChange}
          accept={accept}
          id="file-upload"
          type="file"
          disabled={disabled}
        />
        <label htmlFor="file-upload">
          <div className={styles.label_text_container}>
            {fileName ? (
              <>
                {small ? null : <div className={styles.file_icon}>{successIcon || <TrblFileIcon />}</div>}
                <p className={styles.label_big}>
                  {' '}
                  <TrblTruncate text={fileName} strLen={50} />
                </p>
                {errorMessage && errorMessage.length > 0 && <p className={styles.error_text}> {errorMessage} </p>}
                <p className={styles.label_green}> change file </p>
              </>
            ) : (
              <>
                {!small && <div className={styles.file_icon}>{idleIcon || <TrblFileIcon />}</div>}
                <p className={styles.label_big}> {label} </p>
                <p className={styles.label_green}> click to browse </p>
              </>
            )}
          </div>
          {acceptText && (
            <div className={styles.accept_text_container}>
              <span className={styles.accept_icon}>{acceptIcon || <TrblFileIcon width="12" height="12" />}</span>
              <p> {acceptText} </p>
            </div>
          )}
          {extraMessage && (
            <div style={{ fontSize: '11px', color: '#adadad', textAlign: 'center', height: 0 }}>{extraMessage}</div>
          )}
        </label>
      </div>
    </div>
  );
};
