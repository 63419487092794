import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { PrimaryButton } from '@/components/Shared/Buttons';
import { TrblSimulationIcon } from '../Icons';
import { ModelSimulationStatus } from './ModelSimulationStatus';

import { SelectedModel, Simulation } from '@/types';

import classes from './styles.module.scss';

export const ModelDetails = ({ model }: { model: SelectedModel }) => {
  const { id, modelBaseId, thumbnail, simulations, modificationDate, creationDate } = model;

  const navigate = useNavigate();

  const onOpen = () => {
    navigate(`/editor?mid=${id}`, {
      state: {
        modelId: id,
      },
    });
  };

  return (
    <div className={classes.model_details_container}>
      <img className={classes.model_details_thumbnail} src={thumbnail} alt="model image" />
      <div className={classes.model_details_data}>
        <div className={classes.left_row}>
          <div>
            <span className={classes.model_card__date}>Created {dayjs(creationDate).format('MMM DD YYYY')}</span>
          </div>
          <div className={classes.date_open_row}>
            <span>{modificationDate}</span>
            <div className={classes.model_card_data}>
              <span className={classes.model_card_data__content_icon}>
                <TrblSimulationIcon fill="#ADADAD" />
              </span>
              {`${simulations.length} ${simulations.length === 1 ? 'simulation' : 'simulations'}` ||
                'No simulations available'}
            </div>
          </div>
        </div>
        <div className={classes.right_row}>
          <PrimaryButton onClick={onOpen} width={'fit-content'} label="Open" />
        </div>
      </div>
      <div className={classes.simulations_container}>
        {simulations?.length ? (
          simulations
            .sort((a: Simulation, b: Simulation) => (new Date(b.createdAt) > new Date(a.createdAt) ? 1 : -1))
            .map((simulation: Simulation) => (
              <ModelSimulationStatus
                key={`${simulation.id}`}
                simulation={simulation}
                simulationRun={simulation.lastSimulationRun}
                modelId={id}
                modelBaseId={modelBaseId}
              />
            ))
        ) : (
          <p className={classes.no_simulations}>No simulations have been set up for this model</p>
        )}
      </div>
    </div>
  );
};
