import { cloneDeep } from 'lodash';

import { ActionType, useEditorContext } from '@/context/EditorContext';
import { ActionType as SimActionType, useSimulationContext } from '@/context/SimulationContext';

import { useHasUserTouched } from './useHasUserTouched';
import { useUpdateSimulationAndSettings } from './useUpdatedSimulationAndSettings';

import { Simulation } from '@/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let simulationStatetimeout: any;

const delaySaveSimulation = (time: number) => {
  if (simulationStatetimeout) {
    clearTimeout(simulationStatetimeout);
  }
  return new Promise((resolve) => (simulationStatetimeout = setTimeout(resolve, time)));
};

export const useSaveUpdatedSimulation = () => {
  const { dispatch } = useEditorContext();
  const { dispatch: simDispatch } = useSimulationContext();
  const hasUserTouched = useHasUserTouched();
  const { mutate: updateSimulationAndSettings } = useUpdateSimulationAndSettings();

  const saveSimulation = async (simulation: Simulation | null, saveText = 'Simulation saved ✓'): Promise<string> => {
    const newSimulation = cloneDeep(simulation);
    // @ts-expect-error Argument of type 'Simulation | null' is not assignable to parameter of type 'Simulation'.
    const userTouched = hasUserTouched(newSimulation);

    // eslint-disable-next-line no-async-promise-executor
    return new Promise<string>(async (resolve) => {
      newSimulation!.hasBeenEdited = userTouched;
      simDispatch({
        type: SimActionType.UPDATE_SELECTED_SIMULATION,
        simulation: newSimulation,
      });

      await delaySaveSimulation(1500);

      updateSimulationAndSettings(newSimulation!);
      dispatch({
        type: ActionType.SET_AUTO_SAVE_TEXT,
        text: saveText,
      });

      resolve(saveText);
    });
  };

  return saveSimulation;
};
