import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Drawer, MenuItem, Stack, TextField } from '@mui/material';

import { PrimaryButton, SecondaryButton } from '@/components/Shared/Buttons';
import { hasTASSSeats, hasTSDKSeats } from '../Organization';
import { getUserTASSAccess, getUserTSDKAccess } from '../UsersGrid';

import { SubscriptionDetails, UserAccess } from '../types';

import classes from './styles.module.scss';

import axios from '@/axios';

export type UserFormState = {
  id?: string;
  email: string;
  firstName?: string;
  lastName?: string;
  products: string[];
};

type UserSidepanelProps = {
  user?: UserAccess;
  subscription: SubscriptionDetails;
  onCancel: () => void;
  onSubmit: (values: UserFormState) => void;
};

const getValidationSchema = (originalEmailAddress?: string) => {
  return yup.object().shape({
    email: yup
      .string()
      .email('Not a valid email address')
      .required('Email is required')
      .test('checkDuplicateEmail', 'User with this email already exists', async (value) => {
        if (value && (value !== originalEmailAddress || !originalEmailAddress)) {
          try {
            const response = await axios.get('/api/Admin/GetUserByEmail', {
              params: {
                email: value,
              },
            });

            // Check if a user was found with that email
            if (response.data) {
              // User was found so we fail the validation
              return false;
            } else {
              return true;
            }
          } catch (error) {
            // If the error is a 404, it means the email doesn't exist, so we can return true.
            return true;
          }
        } else {
          return true;
        }
      }),
  });
};

export const UserSidepanel: FC<UserSidepanelProps> = ({ user, subscription, onCancel, onSubmit }) => {
  const [products, setProducts] = useState(getAvailableProducts(subscription));

  const { handleSubmit, control, register } = useForm<UserFormState>({
    resolver: yupResolver(getValidationSchema(user?.email)),
    reValidateMode: 'onSubmit',
    defaultValues: {
      id: user?.id || '',
      email: user?.email || '',
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      products: products == 'Both' ? ['TASS', 'TSDK'] : [products],
    },
  });

  useEffect(() => {
    if (user?.id) {
      register('id', { value: user.id });
      const userHasTASSAccess = getUserTASSAccess(user.productAccesses);
      const userHasTSDKAccess = getUserTSDKAccess(user.productAccesses);
      if (userHasTASSAccess && !userHasTSDKAccess ) {
        setProducts('TASS');
      } else if (!userHasTASSAccess && userHasTSDKAccess ) {
        setProducts('TSDK');
      } else {
        setProducts('Both');
      }
    }
  }, [user]);

  const onChangeProducts = (value: string, onChange: (value: string[]) => void) => {
    setProducts(value);

    if (value == 'Both') {
      onChange(['TASS', 'TSDK']);
    } else {
      onChange([value]);
    }
  };

  return (
    <Drawer open={true} anchor={'right'}>
      <div className={classes.sidepanel_layout}>
        <Stack flex={'auto 1 1'} spacing="20px">
          <p style={{ fontWeight: '600', margin: '8px 0 12px' }}>{user ? 'Edit user' : 'New user'}</p>

          <Controller
            name={'email'}
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                required
                disabled={user?.id !== undefined}
                onChange={onChange}
                onBlur={onBlur}
                label="Email"
                value={value}
                error={error !== undefined}
                helperText={error?.message}
                variant={user?.id !== undefined ? 'filled' : undefined}
              />
            )}
          />
          <Controller
            name={'firstName'}
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                onChange={onChange}
                onBlur={onBlur}
                label="First name"
                value={value}
                error={error !== undefined}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            name={'lastName'}
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                onChange={onChange}
                onBlur={onBlur}
                label="Last name"
                value={value}
                error={error !== undefined}
                helperText={error?.message}
              />
            )}
          />
          {subscription.sdkProduct && (
            <Controller
              name={'products'}
              control={control}
              render={({ field: { onChange }, fieldState: { error } }) => (
                <TextField
                  select
                  value={products}
                  label="Product access"
                  onChange={(e) => onChangeProducts(e.target.value, onChange)}
                  disabled={user?.id !== undefined}
                  variant={user?.id !== undefined ? 'filled' : undefined}
                  error={error !== undefined}
                  helperText={error?.message}
                  style={{ marginTop: '64px' }}>
                  <MenuItem value="TASS" disabled={!hasTASSSeats(subscription)}>
                    Treble web app
                  </MenuItem>
                  <MenuItem value="TSDK" disabled={!hasTSDKSeats(subscription)}>
                    Treble SDK
                  </MenuItem>
                  <MenuItem value="Both" disabled={!hasTASSSeats(subscription) || !hasTSDKSeats(subscription)}>
                    Treble web app + SDK
                  </MenuItem>
                </TextField>
              )}
            />
          )}
        </Stack>
        <div className={classes.sidepanel_actions}>
          <SecondaryButton width={150} label="Cancel" onClick={onCancel} />
          <PrimaryButton width={150} label={user ? 'Update' : 'Create'} onClick={handleSubmit(onSubmit)} />
        </div>
      </div>
    </Drawer>
  );
};

const getAvailableProducts = (subscription: SubscriptionDetails) => {
  if (hasTASSSeats(subscription) && hasTSDKSeats(subscription)) {
    return 'Both';
  } else if (!hasTASSSeats(subscription) && hasTSDKSeats(subscription)) {
    return 'TSDK';
  } else if (hasTASSSeats(subscription) && !hasTSDKSeats(subscription)) {
    return 'TASS';
  } else {
    return '';
  }
};
