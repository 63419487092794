import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Organization } from './types';

import axios from '@/axios';

export type UpdateOrganizationRequest = {
  id: string;
  name: string;
  description: string;
};

type UpdateOrganizationResponnse = Organization;

const updateOrganization = async (organization: UpdateOrganizationRequest) => {
  const { data } = await axios.put<UpdateOrganizationResponnse>(`/api/Organization/Update`, organization);

  return data;
};

export const useUpdateOrganization = () => {
  const queryClient = useQueryClient();

  return useMutation(async (data: UpdateOrganizationRequest) => await updateOrganization(data), {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(['organization', variables.id]);
      queryClient.invalidateQueries(['organizations']);
    },
    onError: () => toast.error('An error occurred while updating the organization!'),
  });
};
