/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from 'react-toastify';
import { datadogRum } from '@datadog/browser-rum';
import Omnitone, { HOARenderer } from 'omnitone/build/omnitone.min.esm.js';
import { Matrix3, Vector3 } from 'three';

import { AudioNodesDict, AudioSettings } from './types';

const AMBISONICS_CHANNEL_COUNT = 9;
const BINAURAL_CHANNEL_COUNT = 2;
export class AudioEngine {
  private static instance: AudioEngine;

  private constructor() {
    this.audioSettings.firstSimNormMax = {
      relMax: 1,
      rulingSource: null,
      firstSim: null,
    };
  }

  public static getInstance(): AudioEngine {
    if (!AudioEngine.instance) {
      AudioEngine.instance = new AudioEngine();
    }

    return AudioEngine.instance;
  }

  // @ts-expect-error the AudioContext can not be initialized because it must be resumed (or created) after a user gesture on the page. https://goo.gl/7K7WLu
  audioContext: AudioContext;

  originalSourceIdArray: string[] = []; // former editor.sourceIdArray

  // @ts-expect-error look at this later
  soaRenderer: HOARenderer;

  isMultichannelEnabled: boolean | null = null;

  isAmbisonicsActive: boolean | null = null;

  audioSettings: AudioSettings = {} as AudioSettings;

  bypassInputGainDict: { [key: string]: GainNode } = {};

  storedReceiverGain: { [key: string]: GainNode } = {};

  rotationMatrix3 = new Float32Array(9);
  rotationMatrix3js = new Matrix3();
  xBasis = new Vector3();
  yBasis = new Vector3();
  zBasis = new Vector3();

  sourceLibrary: any = {};

  sourceGainDictionary: {
    [key: string]: GainNode;
  } = {};

  storedConvolverNode: any = {};

  receiverList: string[] = [];

  resetAudioEngine = () => {
    console.log('[Auralization] RESET AURAL');

    this.audioContext = new AudioContext({
      latencyHint: 'interactive',
      sampleRate: 32000,
    });

    this.soaRenderer = {} as HOARenderer;
    this.sourceGainDictionary = {};
    this.storedConvolverNode = {};
  };

  bypassAll = () => {
    // To bybass the reverb we need to loop through the sourceGainDictionary
    // and make sure that all the items are connected to the bypassInputGainDict.
    // All these if statements are here for TS-checks, but if implemented correctly
    // these should not be necessary... please take a look later @Aslaug
    for (const source_id of this.originalSourceIdArray) {
      if (this.sourceGainDictionary[source_id] && this.bypassInputGainDict[source_id]) {
        if (!this.storedReceiverGain[source_id]) {
          this.sourceGainDictionary[source_id].connect(this.bypassInputGainDict[source_id]);
        } else {
          this.sourceGainDictionary[source_id].disconnect(this.storedReceiverGain[source_id]);
          this.sourceGainDictionary[source_id].connect(this.bypassInputGainDict[source_id]);
        }

        this.storedReceiverGain[source_id] = this.bypassInputGainDict[source_id];
        this.bypassInputGainDict[source_id].connect(this.soaRenderer.input);
      }
    }
  };

  updateStartAngle = (horRot: number, verRot: number) => {
    this.update(horRot, verRot);
  };

  update(azimuth: number, elevation: number) {
    const theta = azimuth;
    const phi = elevation;
    const psi = 0;

    // formula from https://en.wikipedia.org/wiki/Rotation_formalisms_in_three_dimensions
    // Conversion Euler angles to generic 3DoF rotation matrix
    // Note that the omnitone ambisonics matrix seems to have something peculiar so the angles need to be like this.

    const Row1 = new Vector3(
      Math.cos(theta) * Math.cos(psi),
      -Math.cos(phi) * Math.sin(psi) + Math.sin(phi) * Math.sin(theta) * Math.cos(psi),
      Math.sin(phi) * Math.sin(psi) + Math.cos(phi) * Math.sin(theta) * Math.cos(psi)
    );

    const Row2 = new Vector3(
      Math.cos(theta) * Math.sin(psi),
      Math.cos(phi) * Math.cos(psi) + Math.sin(phi) * Math.sin(theta) * Math.sin(psi),
      -Math.sin(phi) * Math.cos(psi) + Math.cos(phi) * Math.sin(theta) * Math.sin(psi)
    );

    const Row3 = new Vector3(-Math.sin(theta), Math.sin(phi) * Math.cos(theta), Math.cos(phi) * Math.cos(theta));

    // Set in row-major order

    this.rotationMatrix3js.set(Row1.x, Row1.y, Row1.z, Row2.x, Row2.y, Row2.z, Row3.x, Row3.y, Row3.z);

    // extract basis (column vectors)
    // a, d, g
    // b, e, h
    // c, f, i

    this.rotationMatrix3js.extractBasis(this.xBasis, this.yBasis, this.zBasis);

    this.rotationMatrix3[0] = this.xBasis.x;
    this.rotationMatrix3[1] = this.yBasis.x;
    this.rotationMatrix3[2] = this.zBasis.x;
    this.rotationMatrix3[3] = this.xBasis.y;
    this.rotationMatrix3[4] = this.yBasis.y;
    this.rotationMatrix3[5] = this.zBasis.y;
    this.rotationMatrix3[6] = this.xBasis.z;
    this.rotationMatrix3[7] = this.yBasis.z;
    this.rotationMatrix3[8] = this.zBasis.z;

    this.soaRenderer.setRotationMatrix3(this.rotationMatrix3);
  }

  /**
   * read initial max and apply factor based on some stored data
   * @param {any} unitValue
   * @param {any} scaling
   */

  rescaleGainBasedOnNormFactor = (unitValue: number, scaling: number) => {
    const originalMax = this.audioSettings.firstSimNormMax.relMax;
    const ratio = scaling / originalMax;

    return unitValue * ratio;
  };

  // master gain
  setMasterGain = (value: number, audioNodesDict: { [sourceId: string]: AudioNodesDict }) => {
    const unitValue = Math.pow(10, value / 20);

    Object.entries(audioNodesDict).forEach((sim: any) => {
      const simId = sim[0];
      Object.entries(sim[1]['receiverGains']).forEach((receiverGainObject: any) => {
        const sourceId = receiverGainObject[0];
        Object.entries(receiverGainObject[1]).forEach((recInputNode: any) => {
          const normFactor = this.audioSettings[simId]?.[sourceId].normFactor;
          const newValue = this.rescaleGainBasedOnNormFactor(unitValue, normFactor);
          if (isFinite(newValue) && newValue !== null) {
            recInputNode[1].gain.value = newValue;
          } else {
            // We need to figure out why some simulations don't have a numberFactor
            // and this is also an experiment to see how custom errors work in DD :)
            const error = new Error(
              `NumberFactor is infinite for sim ${simId} and source ${sourceId}. RelMax is ${this.audioSettings.firstSimNormMax.relMax}`
            );
            datadogRum.addError(error);
          }
        });
      });
    });

    Object.keys(this.bypassInputGainDict).forEach((bypassGainNodeKey) => {
      this.bypassInputGainDict[bypassGainNodeKey].gain.value = unitValue / 4;
    });
  };

  updateSingleGain = (value: number, sourceId: string) => {
    const unitValue = Math.pow(10, value / 20);
    if (this.sourceGainDictionary.hasOwnProperty(sourceId)) {
      this.sourceGainDictionary[sourceId].gain.value = unitValue;
    }
  };

  muteSingleGain = (sourceId: string) => {
    if (this.sourceGainDictionary.hasOwnProperty(sourceId)) {
      this.sourceGainDictionary[sourceId].gain.value = 0.0;
    }
  };

  setOutputBinaural = () => {
    try {
      // Attempt to set the channelCount to BINAURAL_CHANNEL_COUNT (2)
      this.audioContext.destination.channelCount = BINAURAL_CHANNEL_COUNT;

      // Proceed to disconnect if in ambisonics mode
      if (this.isAmbisonicsActive) {
        this.soaRenderer._hoaRotator.output.disconnect(this.audioContext.destination);
      }
      this.soaRenderer.output.connect(this.audioContext.destination);
      this.isAmbisonicsActive = false;
      console.log('Binaural mode, channels out: ', this.audioContext.destination.channelCount);
    } catch (error) {
      datadogRum.addError(error);

      if (this.audioContext.destination.maxChannelCount < BINAURAL_CHANNEL_COUNT) {
        toast.error(
          `Your device supports a maximum of ${this.audioContext.destination.maxChannelCount} channels. Binaural audio cannot be enabled. Check if your internal speakers or hardware support multi-channel audio. The auralization will be played in mono for compatibility. `,
          { toastId: this.originalSourceIdArray[0], autoClose: false }
        );
        this.soaRenderer.output.connect(this.audioContext.destination);
        console.log('Default channelCount: ', this.audioContext.destination.channelCount);
        console.log('Max channelCount: ', this.audioContext.destination.maxChannelCount);
      }
    }
  };

  setOutputAmbisonics = () => {
    try {
      this.audioContext.destination.channelCount = AMBISONICS_CHANNEL_COUNT;
      // disconnect the binaural output first
      // consider setting the gain to zero
      this.soaRenderer.output.disconnect(this.audioContext.destination);
      this.soaRenderer._hoaRotator.output.connect(this.audioContext.destination);
      this.isAmbisonicsActive = true;
      console.log('Ambisonics mode, channels out: ', this.audioContext.destination.channelCount);
    } catch (error) {
      if (this.audioContext.destination.maxChannelCount < AMBISONICS_CHANNEL_COUNT) {
        toast.error(
          `Your device supports a maximum of ${this.audioContext.destination.maxChannelCount} ${
            this.audioContext.destination.maxChannelCount === 1 ? 'channel' : 'channels'
          }. Ambisonics mode cannot be enabled.`,
          { toastId: this.originalSourceIdArray[0], autoClose: false }
        );
        console.log('Default channelCount: ', this.audioContext.destination.channelCount);
        console.log('Max channelCount: ', this.audioContext.destination.maxChannelCount);
        this.soaRenderer.output.connect(this.audioContext.destination);
      }
      datadogRum.addError(error);
    }
  };

  // returns true if browser sound output device has more at least 9 channels
  checkMultiChannelOutput = () => {
    const maxChannelCount = this.audioContext.destination.maxChannelCount;
    if (maxChannelCount >= AMBISONICS_CHANNEL_COUNT) {
      this.isMultichannelEnabled = true;
    } else {
      this.isMultichannelEnabled = false;
    }
    console.log('Max channels: ', this.audioContext.destination.maxChannelCount);
    return this.isMultichannelEnabled;
  };

  onLoad = (newSourceArray: string[]) => {
    console.log('[Auralization] START AURAL');
    this.resetAudioEngine();
    this.originalSourceIdArray = newSourceArray;

    this.soaRenderer = Omnitone.createHOARenderer(this.audioContext, {
      ambisonicOrder: 2,
    });

    // initialize on binaural output
    this.setOutputBinaural();

    this.soaRenderer.setRenderingMode('ambisonic');

    this.soaRenderer.initialize();
  };
}
