import dayjs from 'dayjs';

import { Stack } from '@mui/material';

import { TrblPopup, TrblPopupContent, TrblPopupTitle } from '@/components/Shared/Popup';
import { Text } from '@/components/Shared/Text';

import { useGetSimulationsByModelId } from '@/hooks';

import { Info } from '@/types';

interface IfnoModelPopupProps {
  info: Info;
  onClose?: () => void;
}
export const InfoModelPopup = ({ info, onClose }: IfnoModelPopupProps) => {
  // fetch all simulations for a given model
  const { data: simulations = [] } = useGetSimulationsByModelId(info.id);

  return (
    <TrblPopup aria-labelledby={'Model details'} open={true} onClose={onClose}>
      <TrblPopupTitle onClose={onClose} small>
        Model details
      </TrblPopupTitle>
      <TrblPopupContent>
        <Stack gap="12px" marginBottom="8px">
          <div>
            <Text type="semibold-12px">Name: </Text>
            <Text type="regular-12px">{info.name}</Text>
          </div>
          <div>
            <Text type="semibold-12px">Created: </Text>
            <Text type="regular-12px">
              {info.createdAt ? (
                dayjs(info.createdAt).format('MMM DD YYYY, HH:mm')
              ) : (
                <span style={{ color: '#adadad' }}>Date not available</span>
              )}
            </Text>
          </div>
          <div>
            <Text type="semibold-12px">Created by: </Text>
            <Text type="regular-12px">
              {info.createdBy ? info.createdBy : <span style={{ color: '#adadad' }}>User not available</span>}
            </Text>
          </div>
          <div>
            <Text type="semibold-12px">Simulations: </Text>
            <Text type="regular-12px">{simulations.length}</Text>
          </div>
        </Stack>
      </TrblPopupContent>
    </TrblPopup>
  );
};
