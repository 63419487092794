import { SubscriptionContent, SubscriptionType } from './types';

export const SUBSCRIPTIONS: { [key: string]: SubscriptionContent } = {
  unlimited: {
    name: 'Single User',
    description: 'For solo professionals',
    yearlyPrice: '€239',
    monthlyPrice: '€299',
    per: 'month',
    includedMonthly: [],
    includedYearly: [],
    access: ['Access to all simulation features', 'Unlimited usage', 'One seat', 'Free support'],
    actionText: 'Select',
  },
  teams: {
    name: 'Teams',
    description: 'For team collaboration',
    yearlyPrice: 'Custom',
    per: 'month',
    highlight: 'All from Single User, plus:',
    includedMonthly: [],
    includedYearly: [],
    access: [
      'Team collaboration',
      'Up to 10 seats',
      'Unlimited viewer seats',
      'Dedicated support',
      'Personal training',
    ],
    actionText: 'Request Pricing',
    actionLink: 'https://www.treble.tech/contact?utm_campaign=Pricing&utm_source=TEAMS',
  },
  enterprise: {
    name: 'Enterprise',
    description: 'For broad simulation rollout',
    yearlyPrice: 'Custom',
    per: 'month',
    highlight: 'All from Teams, plus:',
    includedMonthly: [],
    includedYearly: [],
    access: ['Customizable cloud compute', 'Unlimited users', 'Single sign-on (SSO)', 'Priority support'],
    actionText: 'Request Pricing',
    actionLink: 'https://www.treble.tech/contact?utm_campaign=Pricing&utm_source=ENTERPRISE',
  },
};

export const PLANS: {
  [key in SubscriptionType]: any;
} = {
  flexible: {
    planId: 'treble-pro-flexible',
    monthly: {
      priceId: 'treble-pro-flexible-EUR-Monthly',
      addonId: 'treble-pro-flexible-additional-seats-v2-EUR-Monthly',
    },
    yearly: {
      priceId: 'treble-pro-flexible-EUR-Yearly',
      addonId: 'treble-pro-flexible-additional-seats-v2-EUR-Yearly',
    },
  },
  unlimited: {
    planId: 'treble-pro-ultimate',
    monthly: {
      priceId: 'treble-pro-ultimate-EUR-Monthly',
      addonId: 'treble-pro-ultimate-additional-seats-v2-EUR-Monthly',
    },
    yearly: {
      priceId: 'treble-pro-ultimate-EUR-Yearly',
      addonId: 'treble-pro-ultimate-additional-seats-v2-EUR-Yearly',
    },
  },
  teams: {},
  enterprise: {},
  tokens: {
    10: 'treble-pro-token-bundle-10-v2-EUR',
    25: 'treble-pro-token-bundle-25-v2-EUR',
    50: 'treble-pro-token-bundle-50-v2-EUR',
    volume: 'token-bundle-volume-EUR',
    tiers: 'token-bundle-tiers-EUR',
  },
};

export type SUBSCRIPTION_CONTENT = {
  name: string;
  description: string;
  price: string;
  included: string[];
  access: string[];
  highlight?: string;
  tokenBundlePricing?: string[];
};
