import { FC } from 'react';
import { DataGrid } from '@mui/x-data-grid';

import { useBaseContext } from '@/context/BaseContext';

import { useFeatureFlags } from '@/components/FeatureToggles';

import { useUserActions } from '../../hooks';

import { getColumns, gridStyleOverrides } from './utils';

import { ProductAccess, SubscriptionDetails, UserAccess } from '../types';

import styles from './styles.module.scss';

export type UsersGridProps = {
  organizationId: string;
  isLoading: boolean;
  isUsingSso: boolean;
  users: UserAccess[];
  onEditUser: (user: UserAccess) => void;
  subscription: SubscriptionDetails;
};

export const UsersGrid: FC<UsersGridProps> = ({
  organizationId,
  users,
  isLoading,
  isUsingSso,
  onEditUser,
  subscription,
}) => {
  const {
    state: { userInfo },
  } = useBaseContext();

  // User actions
  const { enableDisableUser, deleteUser, updateAdminSettings, updateUserSDKAccess } = useUserActions(organizationId);

  const { automaticBillingFeature } = useFeatureFlags();

  const columns = getColumns(
    userInfo?.id ?? '',
    onEditUser,
    (userId) => deleteUser({ organizationId, userId }),
    updateAdminSettings,
    enableDisableUser,
    updateUserSDKAccess,
    automaticBillingFeature,
    isUsingSso,
    subscription
  );

  return (
    <DataGrid
      sx={gridStyleOverrides}
      onRowDoubleClick={(params) => onEditUser(params.row.id)}
      rows={users}
      loading={isLoading}
      getRowClassName={(params) =>
        `${styles['data-grid-row']} ${
          !getUserTASSStatus(params.row.productAccesses) && !getUserTSDKAccess(params.row.productAccesses)
            ? styles['disabled']
            : ''
        }`
      }
      componentsProps={{
        filterPanel: {
          filterFormProps: {
            deleteIconProps: {
              sx: {
                width: 'initial', // Weird fix needed for the filter panel
              },
            },
          },
        },
      }}
      rowHeight={42}
      headerHeight={42}
      columns={columns}
      autoPageSize={true}
      disableSelectionOnClick
      disableColumnSelector
      initialState={{
        sorting: {
          sortModel: [{ field: 'firstName', sort: 'asc' }],
        },
      }}
    />
  );
};

export const getUserTASSStatus = (productAccesses: ProductAccess[]) => {
  for (const product of productAccesses) {
    if (product.productKey == 'TASS') {
      return product.isEnabled;
    }
  }
  return false;
};

export const getUserTSDKAccess = (productAccesses: ProductAccess[]) => {
  return productAccesses.some((product) => product.productKey === 'TSDK');
};

export const getUserTASSAccess = (productAccesses: ProductAccess[]) => {
  return productAccesses.some((product) => product.productKey === 'TASS');
};
