import { FC, useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Annotations, LegendClickEvent } from 'plotly.js';

import { DecimalPlaces } from './constants';

import { createPlotlyDataObjects, getAnnotations, getBarMode, getXPlotLayout, getYPlotRange, getYTitle } from './utils';

import { ParsedParameterData } from '../ParameterResults/types';
import { ParsedResponseData } from '../ResponsePlot/types';

type ParameterPlotProps = {
  plotlyData: ParsedResponseData[];
  onPlotlyDataCreated: (data: ParsedResponseData[]) => void;
  parameterData: ParsedParameterData[];
  selectedParameter: string;
  showTransitionFrequency: boolean;
  setHasTransitionFrequency: (value: boolean) => void;
};

export const ParameterPlot: FC<ParameterPlotProps> = ({
  plotlyData,
  onPlotlyDataCreated,
  parameterData,
  selectedParameter,
  showTransitionFrequency,
  setHasTransitionFrequency,
}) => {
  const [annotations, setAnnotations] = useState<Array<Partial<Annotations>>>([]);
  const [yAxisRange, setYAxisRange] = useState<number[]>([]);
  const [roundToDecimal, setRoundToDecimal] = useState<DecimalPlaces>(DecimalPlaces['.1f']);

  useEffect(() => {
    let decimals = DecimalPlaces['.1f'];
    if (
      selectedParameter == 'edt' ||
      selectedParameter == 't20' ||
      selectedParameter == 't30' ||
      selectedParameter == 'sti'
    ) {
      decimals = DecimalPlaces['.2f'];
    } else if (selectedParameter == 'ts') {
      decimals = DecimalPlaces['.3f'];
    }
    setRoundToDecimal(decimals);
  }, [selectedParameter]);

  useEffect(() => {
    if (parameterData && roundToDecimal) {
      const { newPlotlyData, hasTransitionFrequency } = createPlotlyDataObjects(
        parameterData,
        selectedParameter,
        showTransitionFrequency,
        roundToDecimal
      );

      setHasTransitionFrequency(hasTransitionFrequency);
      onPlotlyDataCreated(newPlotlyData as ParsedResponseData[]);
    }
  }, [parameterData, selectedParameter, showTransitionFrequency, roundToDecimal]);

  useEffect(() => {
    const newAnnotations = getAnnotations(plotlyData, selectedParameter);
    setAnnotations(newAnnotations);
    const yAxisRange = getYPlotRange(selectedParameter, plotlyData);
    setYAxisRange(yAxisRange);
  }, [plotlyData]);

  const onLegendClickEvent = (clickEvent: Readonly<LegendClickEvent>): boolean => {
    const eventData = clickEvent.data.filter((x, i) => {
      if (
        // @ts-expect-error there is some confusion here for the type
        // visible doesn't exist on Data but it does exist on PlotData
        (x.visible !== 'legendonly' && i !== clickEvent.curveNumber) ||
        // @ts-expect-error there is some confusion here for the type
        // visible doesn't exist on Data but it does exist on PlotData
        (x.visible === 'legendonly' && i === clickEvent.curveNumber)
      ) {
        return x;
      }
    });
    const newAnnotations = getAnnotations(eventData as ParsedResponseData[], selectedParameter);
    setAnnotations(newAnnotations);
    const yAxisRange = getYPlotRange(selectedParameter, eventData as ParsedResponseData[]);
    setYAxisRange(yAxisRange);
    return true;
  };

  const onLegendDoubleClickEvent = (clickEvent: Readonly<LegendClickEvent>) => {
    const visible: boolean[] = [];
    let visibleCount = 0;
    clickEvent.data.forEach((x) => {
      if ('visible' in x) {
        if (x.visible === true) {
          visible.push(true);
          visibleCount++;
        } else visible.push(false);
      } else {
        visible.push(true);
        visibleCount++;
      }
    });

    if (visibleCount > 1 && visible[clickEvent.curveNumber]) {
      const newAnnotations = getAnnotations(
        [clickEvent.data[clickEvent.curveNumber]] as ParsedResponseData[],
        selectedParameter
      );
      setAnnotations(newAnnotations);
      const yAxisRange = getYPlotRange(selectedParameter, [
        clickEvent.data[clickEvent.curveNumber],
      ] as ParsedResponseData[]);
      setYAxisRange(yAxisRange);
    } else if (visibleCount === 1 && visible[clickEvent.curveNumber]) {
      const newAnnotations = getAnnotations(clickEvent.data as ParsedResponseData[], selectedParameter);
      setAnnotations(newAnnotations);
      const yAxisRange = getYPlotRange(selectedParameter, clickEvent.data as ParsedResponseData[]);
      setYAxisRange(yAxisRange);
    }

    return true;
  };

  const xPlotLayout = getXPlotLayout(selectedParameter);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <Plot
          onLegendClick={onLegendClickEvent}
          onLegendDoubleClick={onLegendDoubleClickEvent}
          data={plotlyData}
          config={{
            modeBarButtonsToRemove: ['resetScale2d'],
            displaylogo: false,
            doubleClick: 'autosize',
            responsive: true,
          }}
          style={{ height, width }}
          useResizeHandler={true}
          layout={{
            showlegend: true,
            legend: {
              orientation: 'h',
              borderwidth: 22,
              bordercolor: 'transparent',
              font: {
                size: 11,
                color: '#DADADA',
              },
            },
            autosize: true,
            barmode: getBarMode(selectedParameter),
            xaxis: {
              ...xPlotLayout,
              gridcolor: '#555555',
              title: {
                text: xPlotLayout.title,
                standoff: 15,
                font: {
                  size: 10,
                  color: '#ADADAD',
                },
              },
            },
            yaxis: {
              range: yAxisRange,
              hoverformat: DecimalPlaces[roundToDecimal],
              gridcolor: '#555555',
              title: {
                text: getYTitle(selectedParameter),
                font: {
                  size: 10,
                  color: '#ADADAD',
                },
                standoff: 20,
              },
            },
            paper_bgcolor: '#272727',
            plot_bgcolor: 'transparent',

            font: {
              family: 'Inter, Helvetica, Arial, sans-serif',
              color: '#f5f5f5',
              size: 10,
            },
            margin: {
              l: selectedParameter == 'ts' ? 60 : 40,
              r: 5,
              b: 50,
              t: 20,
              pad: 5,
            },
            annotations: annotations,
          }}
        />
      )}
    </AutoSizer>
  );
};
