/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { getTimeStringByDuration } from '@/utils/trebleFunctions';

import { GridReceiver, Receiver } from '@/types';

const secToTimeString = (durInSec: number) => {
  if (durInSec > 0) {
    dayjs.extend(duration);
    const differenceDuration = dayjs.duration(durInSec * 1000, 'millisecond');

    return getTimeStringByDuration(differenceDuration);
  } else {
    return '';
  }
};

const msToCPUHours = (dur: number) => {
  if (dur > 0) {
    const hours = Math.round((dur / 3.6) * 0.001 * 1000) / 1000;
    return hours;
  } else {
    return 0;
  }
};

export type SimulationTimeEsitmate = {
  initializationTime: number;
  solveTime: number;
  postprocessingTime: number;
  hybridizationTime: number;
  simulationTime: number;
  simulationTimeString: string;
  cpuHours: number;
};

/**
 * TODO: This should not need to be exported. This is due to the fact that run simulation
 * popup is depending on this function that was originally in SolverSettings js file
 */
export const calculateSimulationTimeEstimate = (
  meshResult: any | null,
  impulseLength: number,
  rayCount: number,
  receivers: Receiver[],
  gridReceivers: GridReceiver[],
  taskType?: string | null
) => {
  const cfl = 1;
  const bo = 4;
  let simulationTimeEstForGA: number = 0;
  let simulationTimeEstForDG: number = 0;
  let simulationEstimationsForDG = {
    initializationTime: 0,
    solveTime: 0,
    postprocessingTime: 0,
    hybridizationTime: 0,
  };

  // // for coreweave 2X A100's
  // let provisioningTime = 10;
  // let StartupTimeFixed = 11;
  // let startupTimePrElement = 6.3e-5;
  // let solveTimePrTimestepPrElement = 0.025e-6;
  // let solveTimePrTimestep = 0.7e-3
  // let postProcessingPrReceiverPrSecond = 4;
  // let hybridizationPrReceiverPrSecond = 0.7;

  // for coreweave 1X A100's
  const provisioningTime = 10;
  const StartupTimeFixed = 11;
  const startupTimePrElement = 6.3e-5;
  const solveTimePrTimestepPrElement = 0.05e-6;
  const solveTimePrTimestep = 0.7e-3;
  const postProcessingPrReceiverPrSecond = 4;
  const hybridizationPrReceiverPrSecond = 0.7;
  const postProcessingPrGridReceiverPrSecond = 0.19;

  let gridPointsLength = 0;
  if (gridReceivers) {
    gridReceivers.forEach((grid) => {
      gridPointsLength += grid.points.length;
    });
  }

  if (taskType == 'Hybrid' || taskType == 'GA') {
    // only for GA or Hybrid solvers in seconds
    simulationTimeEstForGA = getTimeEstimateForGA(gridPointsLength, receivers, impulseLength, rayCount);
  }
  if ((taskType == 'Hybrid' || taskType == 'DG') && meshResult) {
    // only for DG or Hybrid solvers

    // calculation of timestep
    const timestep = (((meshResult.elementMinLength * 5) / 0.708) * cfl) / (343 * (bo + 1) * (bo + 1));
    const timestepCount = (1 / timestep) * impulseLength;

    const initializationTime = provisioningTime + StartupTimeFixed + startupTimePrElement * meshResult.elementCount;
    const solveTime =
      timestepCount * solveTimePrTimestep + solveTimePrTimestepPrElement * timestepCount * meshResult.elementCount;
    const postprocessingTime =
      (postProcessingPrGridReceiverPrSecond * gridPointsLength + postProcessingPrReceiverPrSecond * receivers.length) *
      impulseLength;
    const hybridizationTime = hybridizationPrReceiverPrSecond * receivers.length * impulseLength;
    simulationTimeEstForDG = initializationTime + solveTime + postprocessingTime + hybridizationTime;
    simulationEstimationsForDG = {
      initializationTime,
      solveTime,
      postprocessingTime,
      hybridizationTime,
    };
  }

  // use the time estimate that is longer
  const finalEstimate =
    simulationTimeEstForGA > simulationTimeEstForDG ? simulationTimeEstForGA : simulationTimeEstForDG;

  const simulationTimeString = secToTimeString(Math.round(finalEstimate));
  const cpuHours = msToCPUHours(Math.round(finalEstimate));

  return {
    ...simulationEstimationsForDG,
    simulationTime: finalEstimate,
    simulationTimeString,
    cpuHours,
  };
};

// surface receivers time estimate in seconds
const getTimeEstimateForGA = (
  gridPointsLength: number,
  receivers: Receiver[],
  impulseResponseLength: number,
  rayCount: number
) => {
  // Formula from Ingimar and Jóhannes:
  // (1.30187212e+01 * ImpulseLengthSeconds + 1.44880833e-01 * (ReceiversJson.Count() + GridReceivers.Sum(x => x.Points.Count()) ?? 0) + 8.45561857) *
  // 2.24867731e-02 * Math.Sqrt(RayCount);
  // Divide by 4 now that embree is the default raytracer
  const simulationTimeInSeconds =
    ((13.0187212 * impulseResponseLength + 0.144880833 * (receivers.length + gridPointsLength) + 8.45561857) *
      0.0224867731 *
      Math.sqrt(rayCount)) /
    4;

  return simulationTimeInSeconds;
};

export const getEstimatedTransitionFrequency = (modelVolume: number) => {
  const maxT = 1.0;
  const defaultFactor = 4;
  const thirdOctaveBands = [177, 224, 262, 355, 448, 562, 710];
  let volume = 1;
  if (modelVolume > 1) {
    volume = modelVolume;
  }
  const transitionFrequency = 2000 * Math.sqrt(maxT / volume) * defaultFactor;

  // Snaps the calculated frequency to one of the predefined third ocave band upper frequencies
  return thirdOctaveBands.reduce((prev: any, curr: any) =>
    Math.abs(curr - transitionFrequency) < Math.abs(prev - transitionFrequency) ? curr : prev
  );
};
