/* eslint-disable @typescript-eslint/no-explicit-any */
import { Annotations, Font } from 'plotly.js';

import { DecimalPlaces, FREQUENCIES_DEFAULT, FREQUENCIES_DEFAULT_FOR_SPL, NOISE_LEVELS_DEFAULT } from './constants';

import { ResultParameters } from '../../types';
import { ParsedParameterData } from '../ParameterResults/types';
import { ParsedResponseData } from '../ResponsePlot/types';
import { ParsedData } from './types';
import { ResultType } from '@/types';

const getSpatialAverage = (values: number[]) => {
  // Calculated as documented here: hhttps://www.globalspec.com/reference/77882/203279/3-4-spatial-averaging
  return 10 * Math.log10(values.reduce((a, b) => a + Math.pow(10, b / 10), 0) / values.length);
};

const getNormalAverage = (values: number[]) => {
  return values.reduce((a, b) => a + b) / values.length;
};

const getByFrequency = (current: ParsedParameterData, selectedParameterKey: string, roundToDecimal: DecimalPlaces) => {
  const frequencyResults = FREQUENCIES_DEFAULT.map((x) => {
    const frequencyIndex = current.frequencies.indexOf(Number(x));

    let values = current.resultParametersForReceievers.map(
      (resultParametersForReceievers: ResultParameters) =>
        resultParametersForReceievers[selectedParameterKey][frequencyIndex]
    );

    // Filter out the NaNs
    values = [...values].filter((x) => !isNaN(x));
    const roundedValues = values.map((value) => parseFloat(value.toFixed(roundToDecimal)));

    if (roundedValues.length) {
      if (['c50', 'c80', 'g', 'spl'].includes(selectedParameterKey)) {
        return getSpatialAverage(roundedValues);
      } else {
        return getNormalAverage(roundedValues);
      }
    } else {
      return null;
    }
  });

  // A bit of a hack to splice the SPL weighted A, C and Z (which in the results json are treated as separate result parameters) into the SPL array
  if (selectedParameterKey === 'spl') {
    let splA = null;
    let splC = null;
    let splZ = null;

    if (current.resultParametersForReceievers.some((x) => x['spl_a'] && x['spl_c'] && x['spl_z'])) {
      const splAValues = current.resultParametersForReceievers.map(
        (resultParameters: ResultParameters) => resultParameters['spl_a'][0]
      );
      const splCValues = current.resultParametersForReceievers.map(
        (resultParameters: ResultParameters) => resultParameters['spl_c'][0]
      );
      const splZValues = current.resultParametersForReceievers.map(
        (resultParameters: ResultParameters) => resultParameters['spl_z'][0]
      );

      if (splAValues.length && splCValues.length && splZValues.length) {
        splA = getSpatialAverage(splAValues);
        splC = getSpatialAverage(splCValues);
        splZ = getSpatialAverage(splZValues);
      }
    }

    frequencyResults.push(splA);
    frequencyResults.push(splC);
    frequencyResults.push(splZ);
  }

  return frequencyResults;
};

const getByNoiseLevels = (current: ParsedParameterData, roundToDecimal: DecimalPlaces) => {
  return NOISE_LEVELS_DEFAULT.map((_, index: number) => {
    let values = current.resultParametersForReceievers.map(
      (resultParametersForReceievers: ResultParameters) => resultParametersForReceievers['sti'][index]
    );

    // Filter out the NaNs
    values = [...values].filter((x) => !isNaN(x));
    const roundedValues = values.map((value) => parseFloat(value.toFixed(roundToDecimal)));
    if (roundedValues.length) {
      return getNormalAverage(roundedValues);
    } else {
      return null;
    }
  });
};

export const createPlotlyDataObjects = (
  plotData: ParsedParameterData[],
  selectedParameterKey: string,
  showTransitionFrequency: boolean,
  roundToDecimal: DecimalPlaces
) => {
  const initialState: ParsedData = {
    newPlotlyData: [],
  };

  let hasTransitionFrequency = false;
  const parsedData = plotData.reduce((accumulator: ParsedData, current: ParsedParameterData) => {
    if (current.frequencies.length && current.resultParametersForReceievers) {
      const color = current.color;

      const plotlydata = {
        name: current.name,
        type: current.type,
        marker: {
          pattern:
            selectedParameterKey !== 'spl'
              ? undefined
              : ({
                  shape: ['', '', '', '', '', '', '', '', '/', '/', '/'],
                  fillmode: 'overlay',
                  bgcolor: color,
                  fgopacity: '0.7',
                  size: 5,
                } as any), // Needed to cast to any since the pattern type does not support assigning multiple shapes, while this is actually supported
          color,
          // Apply slightly different styling to the SPL weighted A, C and Z
          line:
            selectedParameterKey !== 'spl'
              ? undefined
              : {
                  color: color,
                  width: [0, 0, 0, 0, 0, 0, 0, 0, 1.5, 1.5, 1.5],
                },
        },
        y:
          selectedParameterKey === 'sti'
            ? getByNoiseLevels(current, roundToDecimal)
            : getByFrequency(current, selectedParameterKey, roundToDecimal),
      };

      const parsedData = {
        newPlotlyData: [
          ...accumulator.newPlotlyData,
          {
            ...plotlydata,
          },
        ],
      };

      if (current.resultType === ResultType.Hybrid && current.crossoverFrequency && selectedParameterKey !== 'sti') {
        hasTransitionFrequency = true;

        if (showTransitionFrequency) {
          const minY = getMinMaxY([plotlydata], 'min');
          const maxY = getMinMaxY([plotlydata], 'max');

          const xPos = Math.log2(current.crossoverFrequency / 62.5);
          parsedData.newPlotlyData.push({
            type: 'scatter',
            showlegend: false,
            x: [xPos, xPos, xPos, xPos, xPos],
            y: [minY > 0 ? 0 : minY, maxY / 5, maxY / 1.5, maxY, Math.max(maxY * 1.2, 0)],
            hovertemplate: `Transition frequency: ${current.crossoverFrequency} Hz  `,
            name: '',
            line: {
              dash: 'dot',
              width: 3,
              color: current.color,
            },
            marker: { opacity: 0, size: 0 },
            colorbar: {
              showticklabels: false,
            },
          });
        }
      }

      return parsedData;
    } else {
      return accumulator;
    }
  }, initialState);

  return {
    newPlotlyData: parsedData.newPlotlyData,
    hasTransitionFrequency,
  };
};

export const getXTitle = (selectedParameter: string) => {
  switch (selectedParameter) {
    case 'sti': {
      return 'NOISE CRITERION (NC) CURVE [dB]';
    }
    default: {
      return 'OCTAVE BAND CENTER FREQUENCY [Hz]';
    }
  }
};

export const getYTitle = (selectedParameter: string) => {
  switch (selectedParameter) {
    case 't20': {
      return 'T20 [s]';
    }
    case 't30': {
      return 'T30 [s]';
    }
    case 'edt': {
      return 'EDT [s]';
    }
    case 'c50': {
      return 'C50 [dB]';
    }
    case 'c80': {
      return 'C80 [dB]';
    }
    case 'g': {
      return 'STRENGTH [dB]';
    }
    case 'spl': {
      return 'SPL [dB] re. 20 μPa';
    }
    case 'sti': {
      return 'STI';
    }
    case 'd50': {
      return 'D50';
    }
    case 'ts': {
      return 'CENTRE TIME [s]';
    }
  }
};

export const getBarMode = (selectedParameter: string) => {
  switch (selectedParameter) {
    case 'sti': {
      return undefined;
    }
    default: {
      return 'group';
    }
  }
};

export const getXPlotLayout = (selectedParameter: string) => {
  if (selectedParameter === 'spl') {
    return {
      tickvals: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      ticktext: FREQUENCIES_DEFAULT_FOR_SPL,
      range: [-0.5, 10.5],
      title: getXTitle(selectedParameter),
    };
  } else if (selectedParameter === 'sti') {
    return {
      tickvals: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      ticktext: NOISE_LEVELS_DEFAULT,
      range: [-0.5, 11.5],
      title: getXTitle(selectedParameter),
    };
  } else {
    return {
      tickvals: [0, 1, 2, 3, 4, 5, 6, 7],
      ticktext: FREQUENCIES_DEFAULT,
      range: [-0.5, 7.5],
      title: getXTitle(selectedParameter),
    };
  }
};

function getMinMaxY(plotArray: any[], type: string) {
  let returnNum = 0;
  const numArray: any = [];

  plotArray.forEach((plot: any) => {
    if (plot.type == 'bar') {
      plot.y?.forEach((y: number) => {
        if (!isNaN(y) && y !== null) numArray.push(y);
      });
    }
  });

  if (type === 'min') returnNum = Math.min(...numArray);
  else if (type === 'max') returnNum = Math.max(...numArray);

  return returnNum;
}

export const getYPlotRange = (selectedParameter: string, plotlyData: ParsedResponseData[]) => {
  let min: number = 0;
  let max: number = 0;
  if (plotlyData.length && plotlyData[0]?.y) {
    min = getMinMaxY(plotlyData, 'min');
    max = getMinMaxY(plotlyData, 'max');
  }

  switch (selectedParameter) {
    case 'sti': {
      return [0, 1];
    }
    case 'c50':
    case 'c80':
    case 'g':
    case 'spl': {
      return [min - 3, Math.max(max + 3, 0)];
    }
    default: {
      return [0, max * 1.2];
    }
  }
};

const annotatationStyle: Partial<Annotations> = {
  xref: 'x',
  showarrow: false,
  text: 'N/A',
  hovertext: 'Value not calculated',
  align: 'right',
  y: 0,
};

const annotatationFont: Partial<Font> = {
  family: 'Inter, Helvetica, Arial, sans-serif',
  color: 'black',
};

export const getAnnotations = (plotlyData: ParsedResponseData[], selectedParameter: string): Partial<Annotations>[] => {
  if (plotlyData.length > 0) {
    const annotations: Partial<Annotations>[] = [];
    plotlyData = plotlyData.filter((plot) => plot.type === 'bar');

    // barIndex is the order number of each bar inside of the "bar area" or "bar category"
    plotlyData.forEach((data, barIndex) => {
      // @ts-expect-error Argument of type 'undefined' is not assignable to parameter of type 'never'.
      const dgOnly = data.y.includes(undefined);

      // categoryIndex is index of each "bar area" or "bar category"
      // if annotation has x = categoryIndex then that means that the annotation is places in the middle of the "bar category" x-label
      data.y.forEach((yValue, categoryIndex) => {
        // 1. if we have a DG only simulation then the resultParametersJson returns an empty array for STI
        // which means that the freq array won't map to any results. That gives us y = [undefined, undefin...]
        // for the freq that it was supposed to map to (and null for the rest). In this case we don't want to
        // show any label but instead show a warning  saying "we don't calculate STI for DG only simulations"
        // 2. otherwise show N/A if yValue is null
        const showLabel = (isNaN(yValue as any) || yValue === null) && !dgOnly;
        if (showLabel) {
          const [offset, start] = getPlotValuesBasedOnLength(plotlyData.length);

          // formula for the annotation labels x positon based
          // on how many bars there are because the bars differ in width
          const xPos = (barIndex / plotlyData.length) * (1 - (plotlyData.length / 10) * offset) - start;

          const width = 60;
          const smallHeight = 10;
          const largeHeight = 50;
          const smallFont = 12;
          const largeFont = 16;

          const textAngleSideways = '-90';
          const textAngleRegular = '0';

          annotations.push({
            ...annotatationStyle,
            x: plotlyData.length > 1 ? categoryIndex + xPos : categoryIndex,
            y: getYPlotRange(selectedParameter, plotlyData)[0],
            width: plotlyData.length > 1 ? width : undefined,
            height: plotlyData.length > 1 ? smallHeight : largeHeight,
            valign: plotlyData.length > 1 ? 'middle' : 'top',
            align: plotlyData.length > 1 ? 'right' : 'center',
            textangle: plotlyData.length > 1 ? textAngleSideways : textAngleRegular,
            font: {
              ...annotatationFont,
              color: data.marker.color,
              size: plotlyData.length > 1 ? smallFont : largeFont,
            },
            hoverlabel: {
              bgcolor: data.marker.color,
            },
          });
        }
      });
    });
    return annotations;
  } else {
    return [];
  }
};

const getPlotValuesBasedOnLength = (dataLength: number) => {
  let offset = 1;
  let start = 0.2;

  switch (dataLength) {
    case 3:
      offset = 0.8;
      start = 0.25;
      return [offset, start];
    case 4:
      offset = 0.5;
      start = 0.3;
      return [offset, start];
    case 5:
      offset = 0.4;
      start = 0.32;
      return [offset, start];
    case 6:
      offset = 0.375;
      start = 0.32;
      return [offset, start];
    default:
      return [offset, start];
  }
};
