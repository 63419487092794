import { useState } from 'react';

import { TrblCaretDownIcon } from '@/components/Icons';

import { SUBSCRIPTIONS } from '../constants';

import { SubscriptionLength, SubscriptionType } from '../types';

import styles from '../styles.module.scss';

type SubscriptionOption = {
  type: SubscriptionType;
  selectedSubscription: SubscriptionType | null;
  yearlyPeriod: boolean;
  selectSubscription: (type: SubscriptionType, subscriptionLength: SubscriptionLength) => void;
};

export const SubscriptionOption = ({
  type,
  selectedSubscription,
  yearlyPeriod,
  selectSubscription,
}: SubscriptionOption) => {
  const [foldableIsOpen, setFoldableIsOpen] = useState(false);

  // Safeguard for missing subscription type
  const subscriptionData = SUBSCRIPTIONS[type];
  if (!subscriptionData) {
    return null; // Render nothing if subscription type is not found
  }

  const openFoldable = () => {
    setFoldableIsOpen(!foldableIsOpen);
  };

  return (
    <div
      className={`${styles['subscription-card']} ${
        selectedSubscription?.toLowerCase() === type ? styles['selected'] : ''
      }`}>
      <div style={{ marginBottom: '20px' }}>
        <h3>{subscriptionData.name}</h3>
        <div className={styles['card-description']}>
          <p>{subscriptionData.description}</p>
        </div>
        <h3>
          {yearlyPeriod && subscriptionData.monthlyPrice && (
            <span className={styles['strikethrough-price']}>
              {subscriptionData.monthlyPrice} <span className={styles['strikethrough-line']}></span>
            </span>
          )}
          <span className={styles['card-price']}>
            {yearlyPeriod
              ? subscriptionData.yearlyPrice
              : subscriptionData.monthlyPrice ?? subscriptionData.yearlyPrice}
          </span>
          <span className={styles['card-per']}>/ {subscriptionData.per}</span>
        </h3>
      </div>

      <div>
        <p className={styles['card-highlight']}>{subscriptionData.highlight}</p>
      </div>

      {subscriptionData.tokenBundlePricing && (
        <div className={styles['token-price']}>
          <button onClick={openFoldable}>
            Token bundle pricing
            <span style={{ marginLeft: '6px' }}>
              <TrblCaretDownIcon />
            </span>
          </button>
          <div className={`${styles['foldable']} ${foldableIsOpen ? styles['open'] : ''}`}>
            <ul>
              {subscriptionData.tokenBundlePricing.map((pricing, index) => (
                <li className={styles['card-text']} key={index}>
                  {pricing}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}

      <div>
        <ul className={styles['access-text']}>
          {subscriptionData.access.map((acc, index) => (
            <li className={styles['card-text']} key={index}>
              {acc}
            </li>
          ))}
        </ul>
        {type === 'enterprise' || type === 'teams' ? (
          <a
            className={styles['subscription-action']}
            target="_blank"
            rel="noopener noreferrer"
            href={subscriptionData.actionLink}>
            {subscriptionData.actionText}
          </a>
        ) : (
          <button
            disabled={selectedSubscription ? !['Trial', 'EarlyBird'].includes(selectedSubscription) : false}
            className={styles['subscription-action']}
            onClick={() => selectSubscription(type, yearlyPeriod ? 'yearly' : 'monthly')}>
            {selectedSubscription?.toLowerCase() === type ? 'Your subscription' : subscriptionData.actionText}
          </button>
        )}
      </div>
    </div>
  );
};
