import { useEffect, useState } from 'react';

import { ActionType, useCreateMaterialContext } from '../context/CreateMaterialContext';
import { useAppContext } from '@/context/AppContext';

import { Box, Stack } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { TrblNumberInput } from '@/components/Shared/NumberInput';
import { TextField } from '@/components/Shared/TextField';
import { SelectOption, TrblSelect } from '@/components/Shared/TrblSelect';
import { TrblInfoIcon } from '@/components/Icons';
import { MAX_VALUE, MIN_VALUE } from '@/components/MaterialDetailsPopup/constants';
import { TextArea } from '../../Shared/TextArea';

import { useSubmitEvent } from '@/hooks';

import { FormValues } from '../types';

import styles from '../styles.module.scss';

export const MaterialDetails = () => {
  const { formValues, isFormValid, dispatch } = useCreateMaterialContext();
  const [menuItems, setMenuItems] = useState<SelectOption[]>([]);
  const {
    appState: { materialCategories },
  } = useAppContext();

  useSubmitEvent(isFormValid, [formValues.name, formValues.description]);

  useEffect(() => {
    dispatch({
      type: ActionType.VALID_FORM,
      isFormValid: formValues.name.length > 0 && formValues.category !== '',
    });
  }, [formValues.name, formValues.category]);

  useEffect(() => {
    if (materialCategories.length > 0) {
      const sorted = materialCategories.sort((a, b) => a.localeCompare(b));
      const menuItem = sorted.map((item) => {
        return {
          name: item,
          id: item,
        };
      });
      setMenuItems(menuItem);
    }
  }, [materialCategories]);

  const setFormValues = (values: FormValues) => {
    dispatch({
      type: ActionType.SET_FORM_VALUES,
      formValues: values,
    });
  };

  return (
    <Box component={'div'} display="flex" flexGrow={1} gap="20px" mb="20px">
      <Stack gap={1} flex={'1 1 0'}>
        <TextField
          label="Material name"
          autoFocus
          value={formValues.name}
          onChange={(value) => setFormValues({ ...formValues, name: value })}
        />

        <TrblSelect
          label="Material category"
          value={formValues.category || ''}
          setValue={(value) => setFormValues({ ...formValues, category: value })}
          menuItems={menuItems}
        />
        <div style={{ position: 'relative' }}>
          <TrblNumberInput
            label="Default scattering"
            toFixed={false}
            value={formValues.defaultScattering}
            step={MIN_VALUE}
            min={0.1}
            max={MAX_VALUE}
            onChange={(value) => {
              // @ts-expect-error Type 'number | undefined' is not assignable to type 'number'.
              setFormValues({ ...formValues, defaultScattering: value });
            }}
            onBlur={(value) => {
              if (value !== undefined) {
                setFormValues({ ...formValues, defaultScattering: Number(value?.toFixed(2)) });
              }
            }}
            alignment="center"
          />
          <TrblTooltip
            title={
              <div>
                <p>
                  For the best results when setting a scattering coefficient we recommend consulting the{' '}
                  <a
                    target="_blank"
                    style={{ textDecoration: 'underline' }}
                    href="https://docs.treble.tech/user-guide/getting-started/best_practises#geometrical-acoustics-solver">
                    <b>following guide</b>
                  </a>
                  .
                </p>
              </div>
            }>
            <span className={styles.scatter_info}>
              <TrblInfoIcon width="11" height="11" />
            </span>
          </TrblTooltip>
        </div>
      </Stack>
      <Stack gap={1} flex={'1 1 0'} height={'100%'}>
        <TextArea
          label="Material description (optional)"
          value={formValues.description}
          onChange={(value) => setFormValues({ ...formValues, description: value })}
          style={{ minHeight: '124px' }}
        />
      </Stack>
    </Box>
  );
};
