import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Box, CircularProgress, Stack } from '@mui/material';

import { FileUpload } from '@/components/Shared/FileUpload';
import { Text } from '@/components/Shared/Text';
import {
  TrblCompletedIcon,
  TrblErrorIcon,
  TrblGeometryFolderIcon,
  TrblIfcIcon,
  TrblModelsIcon,
} from '@/components/Icons';
import { ConfirmationDialog } from '../Shared/ConfirmationDialog';
import { TrblTruncate } from '../SoundLibrary/TrblTruncate';
import darkgridImageUrl from './images/darkgrid.png';
import { runIFCPreprocess } from './preProcessIfcFile';

import {
  uploadAndZipFileToTreble,
  useGetSpaceExtractionResult,
  useGetSpaceExtractionTask,
  useStartSpaceExtractionTask,
} from '@/hooks';

import { SpaceExtractionResults } from './types';

import styles from './styles.module.scss';

export const MultiSpaceImportStep1IFC = ({
  file,
  setFile,
  importStarted,
  setImportStarted,
  setPreprocessing,
  ongoingSpaceExtractionId,
  setOngoingSpaceExtractionId,
  setMultiSpaceResults,
  setImportError,
}: {
  file: File | null;
  setFile: (file: File | null) => void;
  importStarted: boolean;
  setImportStarted: (value: boolean) => void;
  setPreprocessing: (value: boolean) => void;
  ongoingSpaceExtractionId: string | null;
  setOngoingSpaceExtractionId: (value: string | null) => void;
  setMultiSpaceResults: (value: SpaceExtractionResults | null) => void;
  setImportError: (value: string | null) => void;
}) => {
  const [spaceExtractionTaskId, setSpaceExtractionTaskId] = useState<string | null>(null);
  const [progressMessage, setProgressMessage] = useState('Uploading file');
  const [preprocessState, setPreprocessState] = useState('');
  const [preprocessMessage, setPreprocessMessage] = useState<string | (() => JSX.Element)>('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [resetFile, setResetFile] = useState(false);

  const { mutate: startSpaceExtractionTask } = useStartSpaceExtractionTask();
  const { data: spaceExtractionTaskData } = useGetSpaceExtractionTask(ongoingSpaceExtractionId, true);
  const { data: spaceExtractionResultsData } = useGetSpaceExtractionResult(spaceExtractionTaskId);

  useEffect(() => {
    if (importStarted) handleStartImport();
  }, [importStarted]);

  // step 1 create uploadSlot
  const handleStartImport = async () => {
    if (file) {
      setOngoingSpaceExtractionId(null);
      setMultiSpaceResults(null);
      setImportError(null);
      toast.info('IFC file upload started');
      setProgressMessage('Uploading file');

      const uploadId = await uploadAndZipFileToTreble(file);

      if (uploadId) handleStartSpaceExtractionTask(uploadId, file.name);
    }
  };

  // step 2 start SpaceExtraction
  const handleStartSpaceExtractionTask = (uploadId: string, filename: string) => {
    startSpaceExtractionTask(
      {
        body: {
          fileUploadId: uploadId,
          filename: filename,
        },
      },
      {
        onSuccess: (response: string) => {
          setOngoingSpaceExtractionId(response);
        },
        onError: () => {
          onSpaceExtractionError('Error occurred while starting IFC file upload');
        },
      }
    );
  };

  // step 3 poll getSpaceExtractionTask until Completed or Error
  useEffect(() => {
    if (ongoingSpaceExtractionId && spaceExtractionTaskData) {
      if (spaceExtractionTaskData.task.status === 'InProgress') {
        setProgressMessage('Processing file');
      }
      if (spaceExtractionTaskData.task.status === 'Completed') {
        setSpaceExtractionTaskId(spaceExtractionTaskData.id);
      } else if (spaceExtractionTaskData.task.status === 'Error') {
        let userStatusMsg = spaceExtractionTaskData.task.userStatusMsg;

        if (userStatusMsg) {
          // Replace the support sentences to align with the web app, because the userStatusMsg is written for SkeptchUp
          userStatusMsg = userStatusMsg.includes('Contact us through the Support button if the issue persists.')
            ? userStatusMsg.replace(
                'Contact us through the Support button if the issue persists.',
                'Please contact support.'
              )
            : userStatusMsg;
        }

        const errorMessage = userStatusMsg || 'Error occurred while uploading IFC file. Please contact support.';
        onSpaceExtractionError(errorMessage, 'Error occurred while uploading IFC file');
      } else if (spaceExtractionTaskData.task.status === 'TimedOut') {
        onSpaceExtractionError('Uploading IFC file timed out');
      }
    }
  }, [spaceExtractionTaskData]);

  // step 4 geometry object ready
  useEffect(() => {
    if (spaceExtractionResultsData) {
      setMultiSpaceResults(spaceExtractionResultsData);

      setImportStarted(false);
      setOngoingSpaceExtractionId(null);

      toast.info('IFC file succesfully uploaded!');
    }
  }, [spaceExtractionResultsData]);

  const onChangeFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      const file = Array.from(event.currentTarget.files)[0];
      setFileForUpload(file);
    }
  };

  const onDropFileUpload = (event: React.DragEvent<HTMLElement>) => {
    if (event.dataTransfer.files) {
      const file = Array.from(event.dataTransfer.files)[0];
      setFileForUpload(file);
    }
  };

  const onSpaceExtractionError = (errorMessage: string, toastErrorMessage?: string) => {
    setImportStarted(false);
    setOngoingSpaceExtractionId(null);
    setImportError(errorMessage);
    toast.error(toastErrorMessage || errorMessage);
  };

  const setFileForUpload = (file: File) => {
    setFile(file);
    setImportError(null);
    setResetFile(false);

    const fileType = file.name.split('.').pop();
    if (fileType == 'ifc') {
      startIFCPreprocess(file);
    }
  };

  const startIFCPreprocess = async (file: File) => {
    setPreprocessing(true);
    setPreprocessState('inprogress');

    const { status, message } = await runIFCPreprocess(file);

    if (status == 'success') {
      setPreprocessing(false);
      setPreprocessState('success');
    } else if (status == 'info') {
      setPreprocessing(false);
      setPreprocessState('success');
      setPreprocessMessage(message);
      setShowConfirmation(true);
    } else if (status == 'failed') {
      toast.error(message);
      setPreprocessState('failed');
    }
  };

  const resetFileInput = () => {
    setResetFile(true);
    setFile(null);
    setPreprocessing(false);
    setPreprocessState('');
    setPreprocessMessage('');
    setImportStarted(false);
    setOngoingSpaceExtractionId(null);
    setShowConfirmation(false);
  };

  return (
    <Stack position={'relative'} height={'100%'} width={'100%'}>
      {importStarted && (
        <Box
          component="div"
          display={'flex'}
          position="absolute"
          height="calc(100% - 24px)"
          width="calc(100% - 24px)"
          top="12px"
          left="12px"
          justifyContent={'center'}
          alignItems="center"
          flexDirection={'column'}
          textAlign="center"
          overflow={'hidden'}
          gap={2}
          className={styles['fade-in']}
          style={{ backdropFilter: 'blur(8px)', zIndex: 2 }}>
          <CircularProgress />
          <Text type="semibold-18px" style={{ lineHeight: 1.5 }}>
            {progressMessage}
            <br />
            <Text type="medium-14px" title={file?.name}>
              <TrblTruncate text={file?.name ?? ''} strLen={50} />
            </Text>
          </Text>

          <Text type="regular-12px" color="#adadad" style={{ lineHeight: 2 }}>
            This can take a few minutes <br />
            <i style={{ marginTop: '-5px' }}> (you can minimise this window)</i>
          </Text>
          <img src={darkgridImageUrl} className={styles['back-img']} alt="Loading image" />
        </Box>
      )}

      <FileUpload
        disabled={importStarted}
        accept={'.ifc, .dxf, .3dm, .obj'}
        acceptText="IFC, DXF, OBJ or 3DM files accepted"
        label="Drop file here to upload"
        onChange={onChangeFileUpload}
        onDropFile={onDropFileUpload}
        idleIcon={<TrblGeometryFolderIcon width="40" height="42" fill={'#3a3a3a'} />}
        successIcon={<TrblIfcIcon width="36" height="42" />}
        acceptIcon={<TrblModelsIcon width="18" height="18" />}
        backgroundImage={darkgridImageUrl}
        newFilename={file?.name}
        shouldReset={resetFile}
        extraMessage={getPreprocessMessage(preprocessState)}
      />

      {showConfirmation && (
        <ConfirmationDialog
          title="IFC file warning"
          message={preprocessMessage}
          onConfirm={() => setShowConfirmation(false)}
          onCancel={resetFileInput}
          confirmLabel="Ok"
        />
      )}
    </Stack>
  );
};

const getPreprocessMessage = (message: string) => {
  return message == 'inprogress' ? (
    <Stack flexDirection="row" alignItems="center" justifyContent="center" gap={1}>
      <CircularProgress style={{ width: '14px', height: '14px' }} color="info" /> Preprocessing IFC file
    </Stack>
  ) : message == 'success' ? (
    <Stack flexDirection="row" alignItems="center" justifyContent="center" gap={1}>
      <TrblCompletedIcon fill="#dadada" /> Preprocessing done
    </Stack>
  ) : message == 'failed' ? (
    <Stack flexDirection="row" alignItems="center" justifyContent="center" gap={1}>
      <TrblErrorIcon /> IFC file has no content
    </Stack>
  ) : null;
};
