import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'; // load on demand

import { Stack } from '@mui/material';

import { TrblPopup, TrblPopupContent, TrblPopupTitle } from '@/components/Shared/Popup';
import { Text } from '@/components/Shared/Text';
import { Divider } from '../Shared/Divider';

import { getTimeStringByDuration } from '@/utils/trebleFunctions';

import { Info, SimulationRunDto } from '@/types';

interface InfoSimulationPopupProps {
  info: Info;
  simulationRun: SimulationRunDto | null;
  onClose?: () => void;
}
export const InfoSimulationPopup = ({ info, simulationRun, onClose }: InfoSimulationPopupProps) => {
  const getSimulationRuntime = (started: string, completed: string) => {
    dayjs.extend(duration);
    const difference = dayjs(completed).diff(dayjs(started), 'ms');
    const differenceDuration = dayjs.duration(difference);

    return getTimeStringByDuration(differenceDuration);
  };

  return (
    <TrblPopup aria-labelledby={'Simulation details'} open={true} onClose={onClose}>
      <TrblPopupTitle onClose={onClose} small>
        Simulation details
      </TrblPopupTitle>
      <TrblPopupContent>
        <Stack gap="12px" marginBottom="8px">
          <div>
            <Text type="semibold-12px">Name: </Text>
            <Text type="regular-12px">{info.name}</Text>
          </div>
          <div>
            <Text type="semibold-12px">Created: </Text>
            <Text type="regular-12px">
              {info.createdAt ? (
                dayjs(info.createdAt).format('MMM DD YYYY, HH:mm')
              ) : (
                <span style={{ color: '#adadad' }}>Date not available</span>
              )}
            </Text>
          </div>
          <div>
            <Text type="semibold-12px">Created by: </Text>
            <Text type="regular-12px">{info.createdBy}</Text>
          </div>
          <div>
            <Text type="semibold-12px">Description: </Text>
            <Text type="regular-12px">
              {info.description ? info.description : <span style={{ color: '#adadad' }}>none</span>}
            </Text>
          </div>
        </Stack>
        {simulationRun && (
          <>
            <div style={{ margin: '16px -20px' }}>
              <Divider />
            </div>

            <Stack gap="12px" marginBottom="8px">
              <div>
                <Text type="medium-12px">Last simulation run</Text>
              </div>
              {simulationRun.completedAt ? (
                <>
                  <div>
                    <Text type="semibold-12px">Completed: </Text>
                    <Text type="regular-12px">{dayjs(simulationRun.completedAt).format('MMM DD YYYY, HH:mm')}</Text>
                  </div>
                  <div>
                    <Text type="semibold-12px">Runtime: </Text>
                    <Text type="regular-12px">
                      {getSimulationRuntime(simulationRun.createdAt, simulationRun.completedAt)}
                    </Text>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <Text type="semibold-12px">Started: </Text>
                    <Text type="regular-12px">{dayjs(simulationRun.createdAt).format('MMM DD YYYY, HH:mm')}</Text>
                  </div>
                  <div>
                    <Text type="semibold-12px">Status: </Text>
                    <Text type="regular-12px">
                      {simulationRun.status == 'Created' || simulationRun.status == 'InProgress'
                        ? 'In progress'
                        : simulationRun.status}
                    </Text>
                  </div>
                </>
              )}
              <div>
                <Text type="semibold-12px">Simulation type: </Text>
                <Text type="regular-12px">{simulationRun.taskType}</Text>
              </div>
              {simulationRun.ranByUserEmail !== info.createdBy && (
                <div>
                  <Text type="semibold-12px">Run by: </Text>
                  <Text type="regular-12px">{simulationRun.ranByUserEmail}</Text>
                </div>
              )}
            </Stack>
          </>
        )}
      </TrblPopupContent>
    </TrblPopup>
  );
};
