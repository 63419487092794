import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';

import { config } from '@/__config__/config';

import { ChargebeeSubscription, ProductDto, SubscriptionDetails } from '../Organization/types';

import axios from '@/axios';

const { adminApiUrl } = config;

export type GetSubscriptionResponse = {
  subscriptionId: string;
  products: ProductDto[];
  chargebeeSubscription: ChargebeeSubscription | null;
};

const MAX_RETRIES = 10;

const getSubscription = async (organizationId: string) => {
  const { data } = await axios.post<GetSubscriptionResponse>(
    `/api/Subscription/GetSubscription`,
    {
      organizationId,
    },
    {
      baseURL: adminApiUrl,
    }
  );

  return data;
};

const transformResponse = (data: GetSubscriptionResponse, sdkProduct: ProductDto | null) => ({
  productKey: data.products[0].productKey,
  subscriptionType: data.products[0].billingType === 'PrepaidOnly' ? 'Flexible' : data.products[0].billingType,
  paymentFrequency: data.products[0].paymentFrequency,
  activeSeats: data.products[0].activeSeats,
  maxSeats: data.products[0].maxSeats,
  expiresAt: data.products[0].expiresAt ?? '',
  chargebeeSubscription: data.chargebeeSubscription,
  sdkProduct: sdkProduct, //if more than one product then the second product is the SDK
});

/** Check if there is a difference between previous data and newly fetched data (in case of ongoing refetch interval)  */
const compareData = (previousData: SubscriptionDetails, newData: SubscriptionDetails) => {
  return (
    previousData.maxSeats === newData.maxSeats &&
    previousData.subscriptionType === newData.subscriptionType &&
    previousData.paymentFrequency === newData.paymentFrequency &&
    previousData.chargebeeSubscription?.cancelledAt === newData.chargebeeSubscription?.cancelledAt
  );
};

export const useGetSubscription = (
  organizationId: string,
  hasPendingChanges: boolean,
  onPendingChangesRegistered: () => void
) => {
  const [previousData, setPreviousData] = useState<SubscriptionDetails | null>(null);
  const retryCountRef = useRef(0);

  return useQuery<GetSubscriptionResponse, unknown, SubscriptionDetails>(
    ['subscription', organizationId],
    () => getSubscription(organizationId),
    {
      onSuccess: (data) => {
        setPreviousData(data);
      },
      enabled: !!organizationId,
      refetchOnWindowFocus: false,
      onError: () => toast.error('An error occurred while retrieving subscription details!'),
      select: (data) => {
        if (data.products[0].productKey == 'TASS') {
          return transformResponse(data, data.products.length > 0 ? data.products[1] : null);
        } else {
          return transformResponse(data, data.products[0]);
        }
      },

      refetchInterval: hasPendingChanges
        ? (data) => {
            // If the cached data is different from the new data or we have already waited for 20 sec then we stop polling
            if ((previousData && data && !compareData(previousData, data)) || retryCountRef.current >= MAX_RETRIES) {
              onPendingChangesRegistered();
              retryCountRef.current = 0;

              return false;
            }
            // We wait for 2 sec before polling again
            else {
              retryCountRef.current += 1;
              return 2000;
            }
          }
        : false,
    }
  );
};
