import { useEffect, useState } from 'react';
import { PlotType } from 'plotly.js';

import { ResultComparison, ResultParameters } from '../../types';
import { ParsedParameterData } from './types';

export const useParameterData = (comparisons: ResultComparison[]) => {
  const [parameterData, setParameterData] = useState<ParsedParameterData[]>([]);

  useEffect(() => {
    if (comparisons) {
      // create a new object that we can filter later while the user
      // interacts with the plots
      const newPlotData: ParsedParameterData[] = comparisons.reduce<ParsedParameterData[]>(
        (acc: ParsedParameterData[], curr: ResultComparison) => {
          const formState = curr?.formState;
          const simulationData = formState?.simulationData;

          if (
            formState &&
            simulationData?.selectedReceiverObjects?.length &&
            (simulationData.selectedSourceObject || simulationData.selectedSourceSummingObject)
          ) {
            // parse the result paramter string to json
            const parsedResultParameters: ResultParameters[] = simulationData.selectedReceiverObjects.map((results) =>
              JSON.parse(results.resultParametersJson)
            );

            // if we have a source summing object we remove results that are not SPL (spl, spl_a, spl_c or spl_z) or STI
            if (simulationData.selectedSourceSummingObject) {
              parsedResultParameters.forEach((result) => {
                const keys = Object.keys(result);
                keys.forEach((key) => {
                  if (!key.includes('spl') && key !== 'sti') {
                    result[key] = [];
                  }
                });
              });
            }

            // get frequencies and the title that we can use later
            const frequencies =
              simulationData?.selectedSourceObject?.frequencies ??
              simulationData.selectedSourceSummingObject?.frequencies;
            const name = formState.title;
            // create a new array with the new object
            return [
              ...acc,
              {
                color: curr.color,
                resultParametersForReceievers: parsedResultParameters,
                frequencies: frequencies ?? [],
                type: 'bar' as PlotType,
                resultType: formState.resultType,
                crossoverFrequency: formState.selectedSimulation?.solverSettings.dgSettings.crossoverFrequency,
                name,
                isSummedSource: !!simulationData.selectedSourceSummingObject,
                sourcePosition: simulationData.selectedSourceObject
                  ? [
                      simulationData.selectedSourceObject.sourceX ?? 0,
                      simulationData.selectedSourceObject.sourceY ?? 0,
                      simulationData.selectedSourceObject.sourceZ ?? 0,
                    ]
                  : [
                      simulationData.selectedSourceSummingObject?.sources[0].x ?? 0,
                      simulationData.selectedSourceSummingObject?.sources[0].y ?? 0,
                      simulationData.selectedSourceSummingObject?.sources[0].z ?? 0,
                    ],
                receiverDetails: simulationData.selectedReceiverObjects.map((receiver) => ({
                  label: receiver.label || (receiver.orderNumber !== undefined ? `R${receiver.orderNumber + 1}` : ''),
                  position: [receiver.x ?? 0, receiver.y ?? 0, receiver.z ?? 0],
                })),
              },
            ];
          } else {
            return acc;
          }
        },
        []
      );

      setParameterData(newPlotData);
    }
  }, [comparisons]);

  return parameterData;
};
