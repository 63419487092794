import { useEffect, useState } from 'react';

import { ALPHABET_OPTIONS } from '@/components/Shared/Auralizer';
import { SelectOptionWithGrouping } from '@/components/Shared/TrblSelect';
import { ActionType, useAuralizerContext } from '../AuralizerContext';
import { ActionType as PreActionType, usePresetContext } from '../PresetContext';
import { AddSimulation } from './AddSimulation';
import { SimulationsSelector } from './SimulationsSelector';

import { useSelectSimulationAsAurSim } from '../hooks/useSelectSimulationAsAurSim';

import { Simulation } from '@/types';

import '../style.scss';

const INITIAL_COMPARISON = ['A'];

export const SimulationComparison = ({
  originalSim,
  menuItems,
}: {
  originalSim: Simulation;
  menuItems: SelectOptionWithGrouping[];
}) => {
  const { simsToCompare, selectedAurSim, dispatch } = useAuralizerContext();
  const { dispatch: preDispatch, selectedPreset, selectedPresetEdited } = usePresetContext();
  const [comparisons, setComparisons] = useState<string[]>(INITIAL_COMPARISON);
  const [defaultValue, setDefaultValue] = useState(originalSim?.id || '');
  const [presetSimulations, setPresetSimulations] = useState<string[] | null>([]);
  const selectSimulationAsAurSim = useSelectSimulationAsAurSim();

  useEffect(() => {
    if (selectedPreset) {
      setPresetSimulations(selectedPreset.simulations);
    }
  }, [selectedPreset?.simulations]);

  useEffect(() => {
    setPresetSimulations(selectedPreset?.simulations ?? []);
  }, [selectedPreset?.simulations]);

  useEffect(() => {
    // Check if the simulations saved on the preset has changed (if the preset is loaded)
    // or if the preset edited state has changed (we loaded / reloaded a preset)
    if (presetSimulations && presetSimulations.length > 0 && !selectedPresetEdited) {
      const [firstSim, ...restOfSim] = presetSimulations;
      setDefaultValue(firstSim);
      if (restOfSim.length > 0) {
        setComparisons([...ALPHABET_OPTIONS].slice(0, presetSimulations.length));
      } else {
        setComparisons(INITIAL_COMPARISON);
      }
    } else if ((presetSimulations === null || presetSimulations?.length === 0) && !selectedPresetEdited) {
      setComparisons(INITIAL_COMPARISON);
      selectSimulationAsAurSim(originalSim.id);
    }
  }, [presetSimulations, selectedPresetEdited]);

  const removeFromComparison = (index: number, simId?: string) => {
    const newComparisons = [...comparisons.slice(0, index), ...comparisons.slice(index + 1)];
    setComparisons(newComparisons);

    // If there is a simId selected for the comparison we are removing we need to update the presets
    // and make sure that a new simulation is selected if this is the selected simulation
    if (simId) {
      const newPresetSimulations = presetSimulations?.filter((id) => id !== simId);
      if (newPresetSimulations) {
        setPresetSimulations(newPresetSimulations);
      }

      const filteredSims = simsToCompare.filter((sim) => sim.id !== simId);
      const newSelectedSim = index >= 1 ? filteredSims[index - 1] : filteredSims[filteredSims.length - 1];

      dispatch({
        type: ActionType.SET_SIMS_TO_COMPARE,
        simulations: filteredSims,
      });
      preDispatch({
        type: PreActionType.SET_PRESET_EDITED,
        presetEdited: true,
      });

      // if the user removes simulation that is the selected aur simulation
      if (selectedAurSim && selectedAurSim.id === simId) {
        selectSimulationAsAurSim(newSelectedSim.id);
      }
    }
  };

  return (
    <div className={`compare-simulation-container`}>
      <div className={`scrollable-list ${comparisons.length >= 4 ? 'fade-out' : ''}`}>
        {comparisons.map((comparison: string, index: number) => (
          <SimulationsSelector
            key={comparison}
            defaultValue={index === 0 ? defaultValue : presetSimulations ? presetSimulations?.[index] : ''}
            originalSim={originalSim}
            menuItems={menuItems}
            disabled={index === 0}
            index={index}
            removeFromComparison={removeFromComparison}
          />
        ))}
      </div>
      <AddSimulation comparisons={comparisons} setComparisons={setComparisons} menuItems={menuItems} />
    </div>
  );
};
