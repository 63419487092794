import dayjs from 'dayjs';

import { Stack } from '@mui/material';

import { TrblPopup, TrblPopupContent, TrblPopupTitle } from '@/components/Shared/Popup';
import { Text } from '@/components/Shared/Text';

import { Info } from '@/types';

interface InfoSpacePopupProps {
  info: Info;
  onClose?: () => void;
}
export const InfoSpacePopup = ({ info, onClose }: InfoSpacePopupProps) => {
  return (
    <TrblPopup aria-labelledby={'Space details'} open={true} onClose={onClose}>
      <TrblPopupTitle onClose={onClose} small>
        Space details
      </TrblPopupTitle>
      <TrblPopupContent>
        <Stack gap="12px" marginBottom="8px">
          <div>
            <Text type="semibold-12px">Name: </Text>
            <Text type="regular-12px">{info.name}</Text>
          </div>
          <div>
            <Text type="semibold-12px">Created: </Text>
            <Text type="regular-12px">
              {info.createdAt ? (
                dayjs(info.createdAt).format('MMM DD YYYY, HH:mm')
              ) : (
                <span style={{ color: '#adadad' }}>Date not available</span>
              )}
            </Text>
          </div>
          <div>
            <Text type="semibold-12px">Created by: </Text>
            <Text type="regular-12px">
              {info.createdBy ? info.createdBy : <span style={{ color: '#adadad' }}>User not available</span>}
            </Text>
          </div>
          <div>
            <Text type="semibold-12px">Description: </Text>
            <Text type="regular-12px">
              {info.description ? info.description : <span style={{ color: '#adadad' }}>none</span>}
            </Text>
          </div>
          <div>
            <Text type="semibold-12px">Models: </Text>
            <Text type="regular-12px">{info.elementCount}</Text>
          </div>
        </Stack>
      </TrblPopupContent>
    </TrblPopup>
  );
};
