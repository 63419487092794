import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';

import { config } from '@/__config__/config';

import axios from '@/axios';

const { adminApiUrl } = config;

export type AddTokenPackageRequest = {
  organizationId: string;
  tokens: number;
};

const addTokenPackage = async (tokenPackage: AddTokenPackageRequest) => {
  await axios.post(`/api/Token/AddTokenPackage`, tokenPackage, { baseURL: adminApiUrl });
};

export const useAddTokenPackage = () => {
  return useMutation(async (data: AddTokenPackageRequest) => await addTokenPackage(data), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      if (error.response?.data.message) toast.error(error.response?.data.message);
      else toast.error('An error occurred while adding tokens!');
    },
  });
};
