import { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';

import { useBaseContext } from '@/context/BaseContext';

import { ConfirmationDialog } from '@/components/Shared/ConfirmationDialog';
import { EditProjectPopup, EditSpacePopup, InfoSpacePopup, ProjectGroup } from './components';
import { InfoProjectPopup } from './components/InfoProjectPopup';

import { useDeleteProject, useDeleteSpace } from '@/hooks';

import { IActions, Info, ProjectAndUsersDto, SpaceDetailsDto, SpaceDto } from '@/types';

import classes from './styles.module.scss';

const projectsWithSpaces: Record<string, SpaceDetailsDto[]> = {};
interface ProjectsViewProps {
  availableProjects: ProjectAndUsersDto[];
  projectFilter: string;
  projectSearchString: string;
  viewType?: string;
  minimized: boolean;
}

export const ProjectsView = ({
  availableProjects,
  viewType = 'cards',
  projectFilter,
  projectSearchString,
  minimized,
}: ProjectsViewProps) => {
  const {
    state: { userInfo },
  } = useBaseContext();
  const queryClient = useQueryClient();

  const deleteSpace = useDeleteSpace();
  const deleteProject = useDeleteProject();

  const [projectsLoaded, setProjectsLoaded] = useState<string[]>([]);

  const allProjectsLoaded = useMemo(
    () => availableProjects.filter((x) => x.spacesCount > 0).every((x) => projectsLoaded.includes(x.id)),
    [projectsLoaded, availableProjects]
  );

  const [selectedSpace, setSelectedSpace] = useState<SpaceDetailsDto>();
  const [selectedSpaceInfo, setSelectedSpaceInfo] = useState<Info>({} as Info);
  const [selectedProject, setSelectedProject] = useState<ProjectAndUsersDto>();
  const [selectedProjectInfo, setSelectedProjectInfo] = useState<Info>({} as Info);

  const [showSpacePopup, setShowSpacePopup] = useState(false);
  const [showSpaceInfoPopup, setShowSpaceInfoPopup] = useState(false);
  const [showProjectPopup, setShowProjectPopup] = useState(false);
  const [showProjectInfoPopup, setShowProjectInfoPopup] = useState(false);
  const [showDeleteSpaceDialog, setShowDeleteSpaceDialog] = useState(false);
  const [showDeleteProjectDialog, setShowDeleteProjectDialog] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  // Scroll to the element with the id in the hash (i.e. when opening from a shared project email link)
  useEffect(() => {
    if (allProjectsLoaded) {
      const scrollToElement = () => {
        const hash = window.location.hash.substring(1); // Remove the #
        if (hash) {
          const element = document.getElementById(hash);
          if (element && containerRef.current) {
            // Scroll to the element with a small offset to account for the header
            containerRef.current.scrollTop = element.offsetTop - 60;
            // Remove the hash from the URL
            history.replaceState(null, '', window.location.pathname);
          }
        }
      };

      scrollToElement();
    }
  }, [allProjectsLoaded]);

  const getSpaceActions = (space: SpaceDetailsDto): IActions[] => [
    {
      value: 'Edit',
      onClick: () => {
        setSelectedSpace(space);
        setShowSpacePopup(true);
      },
      key: 'edit-space',
    },
    {
      value: 'Details',
      onClick: () => {
        setSelectedSpaceInfo({
          id: space.id,
          name: space.name,
          description: space.description,
          createdAt: space.createdAt,
          createdBy: space.createdByUserEmail,
          elementCount: space.modelCount,
        });
        setShowSpaceInfoPopup(true);
      },
      key: 'details-space',
    },
    {
      value: 'Delete',
      onClick: () => {
        setSelectedSpace(space);
        setShowDeleteSpaceDialog(true);
      },
      key: 'delete-space',
    },
  ];

  const deleteSpaceFunc = (id: string, space: SpaceDetailsDto) => {
    deleteSpace.mutate(
      { spaceId: id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['spaces-for-project', space.projectId]);
          toast.info("'" + space.name + "' deleted");
        },
        onError: () => {
          toast.error('An error occurred while deleting Space');
        },
      }
    );
  };

  const updateSpaceCard = (updatedSpace: SpaceDto) => {
    setShowSpacePopup(false);
    queryClient.invalidateQueries(['spaces-for-project', updatedSpace.projectId]);
  };

  const getProjectActions = (project: ProjectAndUsersDto): IActions[] => {
    const actions = [
      {
        value: 'Edit',
        onClick: () => {
          setSelectedProject(project);
          setShowProjectPopup(true);
        },
        key: 'edit-project',
      },
      {
        value: 'Details',
        onClick: () => {
          const creator = project.projectUsers.find((user) => user.id == project.createdBy);
          setSelectedProjectInfo({
            id: project.id,
            name: project.name,
            description: project.description,
            createdAt: project.createdAt,
            createdBy: creator?.email,
            elementCount: project.spacesCount,
            projectUsers: project.projectUsers.filter((user) => user.id != project.createdBy),
          });
          setShowProjectInfoPopup(true);
        },
        key: 'details-project',
      },
    ];

    if (userInfo!.roles.includes('Admin') || project.createdBy === userInfo!.id) {
      actions.push({
        value: 'Delete',
        onClick: () => {
          setSelectedProject(project);
          setShowDeleteProjectDialog(true);
        },
        key: 'delete-project',
      });
    }

    return actions;
  };

  const onDeleteProject = (project: ProjectAndUsersDto) => {
    deleteProject.mutate(project.id, {
      onSuccess: () => {
        queryClient.invalidateQueries(['projects']);
        toast.info("'" + project.name + "' deleted");
      },
      onError: () => {
        toast.error('An error occurred while deleting Project');
      },
    });
  };

  const handleProjectPopupClosed = () => {
    setShowProjectPopup(false);
  };

  const handleProjectUpdated = () => {
    handleProjectPopupClosed();
    queryClient.invalidateQueries(['projects']);
  };

  const cardStyling = viewType === 'cards' ? classes.cards_container : classes.list_container;

  const filterProject = (project: ProjectAndUsersDto) => {
    if (project.isDeleted) return false;

    let showProject = false;

    // check the project filter dropdown first
    if (
      projectFilter === 'all' ||
      (projectFilter === 'myProjects' && project.createdBy == userInfo!.id) ||
      (projectFilter === 'sharedWithMe' && project.createdBy !== userInfo!.id)
    ) {
      // if no search string then show the projects
      if (projectSearchString == '') {
        showProject = true;
        // else if there is a search string and it's included in the project or space name , then also show project
      } else if (
        project.name.toLowerCase().includes(projectSearchString.toLowerCase()) ||
        projectsWithSpaces[project.id].find((y: SpaceDetailsDto) =>
          y.name.toLowerCase().includes(projectSearchString.toLowerCase())
        )
      ) {
        showProject = true;
      }
    }

    return showProject;
  };
  return (
    <div ref={containerRef} className={cardStyling}>
      {userInfo &&
        availableProjects
          .filter((project) => (projectsLoaded ? filterProject(project) : true))
          .map((project) => (
            <ProjectGroup
              key={project.id}
              project={project}
              viewType={viewType}
              loggedInUserId={userInfo.id}
              getSpaceActions={getSpaceActions}
              getProjectActions={getProjectActions}
              onSpacesLoaded={(spaces: SpaceDetailsDto[]) => {
                setProjectsLoaded((prevProjectsLoaded) => [...prevProjectsLoaded, project.id]);
                projectsWithSpaces[project.id] = spaces;
              }}
              isMinimized={minimized}
              projectSearchString={projectSearchString}
            />
          ))}
      {showSpacePopup && (
        <EditSpacePopup
          onClose={() => setShowSpacePopup(false)}
          onUpdate={(reponse) => updateSpaceCard(reponse)}
          name={selectedSpace?.name ?? ''}
          description={selectedSpace?.description ?? ''}
          id={selectedSpace?.id ?? ''}
        />
      )}
      {showSpaceInfoPopup && <InfoSpacePopup info={selectedSpaceInfo} onClose={() => setShowSpaceInfoPopup(false)} />}
      {showProjectPopup && (
        <EditProjectPopup
          onClose={handleProjectPopupClosed}
          onUpdate={handleProjectUpdated}
          id={selectedProject?.id ?? ''}
          name={selectedProject?.name ?? ''}
          description={selectedProject?.description ?? ''}
        />
      )}
      {showProjectInfoPopup && (
        <InfoProjectPopup info={selectedProjectInfo} onClose={() => setShowProjectInfoPopup(false)} />
      )}

      {selectedSpace && showDeleteSpaceDialog && (
        <ConfirmationDialog
          title={`Delete space`}
          message={() => (
            <span>
              Are you sure you want to delete <b>{selectedSpace.name}</b> ?
            </span>
          )}
          onConfirm={() => {
            deleteSpaceFunc(selectedSpace.id, selectedSpace);
            setShowDeleteSpaceDialog(false);
          }}
          onCancel={() => setShowDeleteSpaceDialog(false)}
        />
      )}
      {selectedProject! != undefined && showDeleteProjectDialog && (
        <ConfirmationDialog
          title={`Delete project`}
          message={() => (
            <span>
              Are you sure you want to delete <b>{selectedProject.name}</b> ?
            </span>
          )}
          onConfirm={() => {
            if (selectedProject) {
              onDeleteProject(selectedProject);
              setShowDeleteProjectDialog(false);
            }
          }}
          onCancel={() => setShowDeleteProjectDialog(false)}
        />
      )}
    </div>
  );
};
