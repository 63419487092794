import { SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ActionType, useAppContext } from '@/context/AppContext';

import { Skeleton } from '@mui/material';

import { TrblSimulationIcon } from '../Icons';
import { TrblTooltip } from '../Shared';
import { TrblActionsMenu } from '../TrblActionsMenu';
import defaultPlaceholderImageUrl from './images/default-placeholder.png';

import { useGetSimulationsByModelId } from '@/hooks';

import { nonWatertightMessage } from '@/utils/constants';

import { IActions, Model } from '@/types';

import classes from './styles.module.scss';

export const ModelCard = ({
  name,
  id,
  thumbnail = '',
  model,
  modelActions,
}: {
  name: string;
  id: string;
  thumbnail: string;
  model: Model;
  modelActions: IActions[];
}) => {
  const navigate = useNavigate();
  const {
    dispatch,
    appState: { selectedModel },
  } = useAppContext();
  const [imgLoaded, setImgLoaded] = useState(false);

  // fetch all simulations for a given model
  const { data: simulations = [], isLoading } = useGetSimulationsByModelId(model.id || '');

  const handleSelected = (e: SyntheticEvent) => {
    const target = e.target as HTMLElement;

    // hack to not select the model card if an action menu has been opened
    if (target.classList.contains('MuiBackdrop-root')) return;

    dispatch({
      type: ActionType.SET_SELECTED_MODEL,
      payload: {
        name,
        id: `${model.id}` || '',
        modelBaseId: model?.modelBaseId,
        thumbnail: thumbnail || defaultPlaceholderImageUrl,
        simulations: simulations,
        creationDate: model?.creationTime,
        modelUploadId: model?.modelUploadId,
      },
    });
  };

  const onOpen = () => {
    navigate(`/editor?mid=${model.id}`, {
      state: {
        modelId: model.id,
      },
    });
  };

  return (
    <div
      onDoubleClick={onOpen}
      className={`${classes.model_card__container} base-model-card ${
        selectedModel?.id === model.id ? classes.selected : ''
      } ${isLoading ? classes.disabled : ''} `}
      onClick={handleSelected}>
      <img
        className={classes.model_thumbnail}
        src={thumbnail || defaultPlaceholderImageUrl}
        style={{ opacity: imgLoaded ? '1' : '0' }}
        onLoad={() => {
          setImgLoaded(true);
        }}
        alt="model image"
      />
      {(isLoading && !simulations.length) || !imgLoaded ? (
        <>
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{ width: '100%', height: '60px', margin: '10px 0 10px 15px', opacity: 0.15 }}
          />
        </>
      ) : (
        <>
          <span className={classes.model_name}>
            {name}{' '}
            {model.nonWatertight && (
              <TrblTooltip title={nonWatertightMessage}>
                <div className="nonwatertight-symbol"></div>
              </TrblTooltip>
            )}
          </span>
          <div className={classes.card_model_data}>
            <div className={classes.card_model_data__content}>
              <span className={classes.card_model_data__content_icon}>
                <TrblSimulationIcon fill="#ADADAD" />
              </span>
              {`${simulations.length} ${simulations.length === 1 ? 'simulation' : 'simulations'}` ||
                'No simulations available'}
            </div>
            <TrblActionsMenu actions={modelActions} id={id} title="View Model actions" size="large" />
          </div>
        </>
      )}
    </div>
  );
};
