import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';

import { config } from '@/__config__/config';

import { UserAccess } from '../Organization/types';

import axios from '@/axios';

const { adminApiUrl } = config;

const getOrganizationUserProductAccesses = async (organizationId: string): Promise<UserAccess[]> => {
  const { data } = await axios.post<UserAccess[]>(
    `/api/Subscription/GetOrganizationUserProductAccesses?organizationId=${organizationId}`,
    null,
    {
      baseURL: adminApiUrl,
    }
  );

  return data;
};

export const useGetOrganizationUsersAccesses = (organizationId: string) => {
  return useQuery<UserAccess[]>(
    ['product-accesses', organizationId],
    () => getOrganizationUserProductAccesses(organizationId),
    {
      enabled: !!organizationId,
      onError: () => toast.error('An error occurred while retrieving subscription details!'),
    }
  );
};
