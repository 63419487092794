import { useEffect, useState } from 'react';
import { datadogRum } from '@datadog/browser-rum';

import { ActionType, useCreateMaterialContext } from '../context/CreateMaterialContext';

import { BufferLoadingScreen } from '@/components/Shared/BufferLoadingScreen';
import { Label } from '@/components/Shared/Label';
import { DarkBox } from '@/components/Shared/Popup';
import {
  ABS_PLOT_TITLE,
  ABS_RANGE,
  REF_PLOT_TITLE,
  REF_RANGE,
  REF_X_VALS,
  TICK_VALS,
} from '@/components/MaterialDetailsPopup/constants';
import { MaterialDetailsPlot } from '@/components/MaterialDetailsPopup/MaterialDetailsPlot';
import { MaterialFileUpload } from './MaterialFileUpload';
import { MissingKeys } from './MissingKeys';
import { SimpleInputTable } from './SimpleInputTable';

import {
  FREQUENCY_RANGE_FULL_OCTAVE,
  FULL_OCTAVES,
  GREEN_PLOT_COLOR,
  ORANGE_PLOT_COLOR,
  PLOT_WIDTH,
  VALID_CSV_TYPES,
} from '../constants';

import { getFirstValuesForKeys } from '../utils/getFirstValuesForKeys';
import { parseCSV } from '../utils/parseCsv';
import { parseInput } from '../utils/parseInput';

import styles from '../styles.module.scss';

type SimpleInputProps = {
  plotHeight: number;
};

export const SimpleInput: React.FC<SimpleInputProps> = ({ plotHeight }) => {
  const { formDisabled, materialFit, fullOctaveData, upload, dispatch } = useCreateMaterialContext();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [disableEdit, setDisableEdit] = useState<boolean>(false);

  useEffect(() => {
    setDisableEdit(formDisabled || materialFit !== null);
  }, [formDisabled, materialFit]);

  useEffect(() => {
    if (upload) {
      setErrorMessage(null);
      if (VALID_CSV_TYPES.includes(upload.file.type)) {
        const absRecord = parseCSV(upload.contents as string);
        const absRecordWithValues = getFirstValuesForKeys(absRecord, FULL_OCTAVES);
        datadogRum.addAction(`Successful CSV file upload ${absRecordWithValues}`);
        dispatch({
          type: ActionType.SET_TARGET_COEFFICIENTS,
          fullOctaveData: absRecordWithValues,
        });
      } else {
        const absRecord = parseInput(upload.contents as string);
        if (Object.keys(absRecord).length === 0) {
          datadogRum.addError(`Invalid or empty file: ${absRecord}`);
          setErrorMessage('Invalid or empty file');
        } else {
          datadogRum.addAction(`Successful text file upload ${absRecord}`);
          dispatch({
            type: ActionType.SET_TARGET_COEFFICIENTS,
            fullOctaveData: absRecord as Record<number, number>,
          });
        }
      }
    }
  }, [upload]);

  return (
    <div>
      <div>
        <div className={styles.space_between}>
          <Label> Absorption coefficient (Random incidence)</Label>
          <div className={styles.justify_right}>
            <span style={{ color: '#ff8a8a', marginRight: '20px' }}>{errorMessage}</span>
            <MaterialFileUpload
              simple
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              disabled={disableEdit}
            />
          </div>
        </div>
        <div style={{ margin: '16px 0 24px', width: '92%' }}>
          <SimpleInputTable
            editable={!disableEdit}
            data={fullOctaveData}
            materialFit={materialFit}
            fittedAbsorptionCoefficients={materialFit?.material?.FittedAbsorptionCoefficients}
          />
        </div>
      </div>
      <MissingKeys data={fullOctaveData} keys={FULL_OCTAVES} />
      <DarkBox>
        <div className={styles.space_between}>
          {formDisabled && <BufferLoadingScreen />}
          <MaterialDetailsPlot
            range={ABS_RANGE}
            ticktext={FREQUENCY_RANGE_FULL_OCTAVE}
            tickvals={TICK_VALS}
            xData={Object.keys(fullOctaveData)}
            plots={[
              Object.values(fullOctaveData) as number[],
              materialFit?.material?.FittedAbsorptionCoefficients ?? [],
            ]}
            plotTitles={['Target', 'Result']}
            plotColors={[ORANGE_PLOT_COLOR, GREEN_PLOT_COLOR]}
            plotDashed={[false, true]}
            yAxesTitle={ABS_PLOT_TITLE}
            plotHeight={plotHeight}
            plotWidth={PLOT_WIDTH}
          />
          <MaterialDetailsPlot
            range={REF_RANGE}
            ticktext={FREQUENCY_RANGE_FULL_OCTAVE}
            tickvals={TICK_VALS}
            xData={REF_X_VALS}
            plots={[
              materialFit?.material_metadata?.RealReflectionCoefficient ?? [],
              materialFit?.material_metadata?.ImagReflectionCoefficient ?? [],
            ]}
            plotTitles={['Result real', 'Result imag']}
            plotColors={[GREEN_PLOT_COLOR, GREEN_PLOT_COLOR]}
            plotDashed={[true, true]}
            plotMarker={['circle', 'cross']}
            yAxesTitle={REF_PLOT_TITLE}
            plotHeight={plotHeight}
            plotWidth={PLOT_WIDTH}
          />
        </div>
      </DarkBox>
    </div>
  );
};
