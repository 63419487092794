import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ActionType, useAppContext } from '@/context/AppContext';
import { useBaseContext } from '@/context/BaseContext';

import { ConfirmationDialog } from '@/components/Shared/ConfirmationDialog';
import { TrblTooltip } from '@/components/Shared/TrblTooltip';
import {
  ModelCard,
  ModelDetailsHeader,
  PageLayout,
  RecentSimulationsHeader,
  RightSidebar,
  TrblSharedProjectIcon,
} from '@/components';
import { ProjectBreadcrumbs } from '@/components/Breadcrumbs/ProjectBreadcrumbs';
import { EditModelPopup, InfoModelPopup } from '@/components/ModelCard';

import {
  useDeleteModelBase,
  useGetDownloadUrl,
  useGetProjectById,
  useGetSpaceById,
  useGetThumbnailsForModelBases,
} from '@/hooks';

import { IActions, Info, Model, ModelBaseDto } from '@/types';

import classes from './styles.module.scss';

export const SpaceOverview = () => {
  const params = useParams();
  const {
    dispatch,
    appState: { selectedModel },
  } = useAppContext();
  const {
    state: { userInfo },
  } = useBaseContext();

  const spaceId = params.id as string;

  const { data: space, isFetching: isFetchingSpace, refetch: refetchSpaceById } = useGetSpaceById(spaceId);
  const { data: project } = useGetProjectById(space?.projectId || '');
  const [modelBases, setModelBases] = useState(space?.modelBases);
  const { data: thumbnails, isFetching: isFetchingThumbnails } = useGetThumbnailsForModelBases(
    modelBases?.map((modelBase) => modelBase.id) || []
  );
  const deleteModelBase = useDeleteModelBase();

  const [sourceModelUploadId, setSourceModelUploadId] = useState('');
  const { data: downloadSourceModelUrl } = useGetDownloadUrl(sourceModelUploadId);

  const [selectedModelBase, setSelectedModelBase] = useState({} as { id: string; name: string });
  const [selectedModelInfo, setSelectedModelInfo] = useState<Info>({} as Info);

  const [showPopup, setShowPopup] = useState(false);
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  useEffect(
    () => () => {
      dispatch({
        type: ActionType.SET_SELECTED_MODEL,
        payload: null,
      });
      setSourceModelUploadId('');
    },
    []
  );

  useEffect(() => {
    if (space?.modelBases) {
      setModelBases(
        space.modelBases.sort((a: ModelBaseDto, b: ModelBaseDto) =>
          new Date(b.createdAt) > new Date(a.createdAt) ? 1 : -1
        )
      );
      dispatch({
        type: ActionType.SET_MODELS_IN_SPACE,
        payload: space?.modelBases,
      });
    }
  }, [space, isFetchingThumbnails]);

  const getModelActions = (modelBase: {
    id: string;
    name: string;
    application: string;
    sourceModelFileUploadId?: string;
    model: Model;
  }): IActions[] => [
    {
      value: 'Edit',
      onClick: () => {
        setSelectedModelBase(modelBase);
        setShowPopup(true);
      },
      key: 'edit-space',
    },
    {
      value: 'Details',
      onClick: () => {
        setSelectedModelInfo({
          name: modelBase.name,
          id: modelBase.model.id,
          createdAt: modelBase.model.creationTime,
          createdBy: modelBase.model.createdByUserEmail,
        });
        setShowInfoPopup(true);
      },
      key: 'details-space',
    },
    {
      value: 'Delete',
      onClick: () => {
        setSelectedModelBase(modelBase);
        setShowDeleteConfirmation(true);
      },
      key: 'delete-space',
    },
    {
      value: 'Download source model',
      onClick: () => {
        if (modelBase.sourceModelFileUploadId && modelBase.application !== 'sketchup') {
          setSourceModelUploadId(modelBase.sourceModelFileUploadId);
        }
      },
      disabled: modelBase.application == 'sketchup',
      title: modelBase.application == 'sketchup' ? 'Not available for SketchUp models' : '',
      key: 'download-source-model',
    },
  ];

  useEffect(() => {
    if (downloadSourceModelUrl) {
      const link = document.createElement('a');
      link.download = 'source_model';
      link.href = downloadSourceModelUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [downloadSourceModelUrl]);

  function deleteModelBaseFunc(id: string, modelBase: { id: string; name: string }) {
    deleteModelBase.mutate(
      { modelBaseId: id },
      {
        onSuccess: () => {
          refetchSpaceById();
          // if selectedModel same as the deleted model, then update selectedModel as nothing selected
          if (selectedModel?.modelBaseId === modelBase.id) {
            dispatch({
              type: ActionType.SET_SELECTED_MODEL,
              payload: null,
            });
          }

          toast.info("'" + modelBase.name + "' deleted");
        },
        onError: () => {
          toast.error('An error occurred while deleting Model');
        },
      }
    );
  }
  function updateCard(updatedModelBase: ModelBaseDto) {
    setShowPopup(false);

    setModelBases(
      modelBases?.map((modelBase) => {
        const temp = { ...modelBase };
        if (temp.id === updatedModelBase.id) {
          temp.name = updatedModelBase.name;
        }
        return temp;
      })
    );
    // if selectedModel same as the updated model, then update selectedModel name
    if (selectedModel?.modelBaseId === updatedModelBase.id) {
      dispatch({
        type: ActionType.SET_SELECTED_MODEL,
        payload: { ...selectedModel, name: updatedModelBase.name },
      });
    }
  }

  return (
    <PageLayout
      extraHeader={
        !isFetchingSpace &&
        space &&
        project && (
          <ProjectBreadcrumbs
            projectUsers={project.projectUsers}
            loggedInUserId={userInfo?.id}
            creatorId={project.createdBy}
            items={[
              {
                text: project.name,
                to: '/project/' + project.id,
                icon:
                  project.createdBy !== userInfo?.id ? (
                    <TrblTooltip title="Project shared with you">
                      <span>
                        <TrblSharedProjectIcon width="14" height="14" fill="#c0c0c0" />
                      </span>
                    </TrblTooltip>
                  ) : undefined,
              },
              {
                text: space.name,
              },
            ]}
          />
        )
      }
      sidepanel={<RightSidebar />}
      sidepanelExtraHeader={
        selectedModel ? (
          <ModelDetailsHeader>{selectedModel.name}</ModelDetailsHeader>
        ) : (
          <RecentSimulationsHeader showBorder={false} />
        )
      }
      mainContentMinWidth="600px">
      <div className={`${classes.space_details_container}`}>
        {!isFetchingThumbnails &&
          modelBases?.map(
            ({ id, name, revisions, model }) =>
              revisions > 0 && (
                <ModelCard
                  key={id}
                  id={id}
                  model={model}
                  modelActions={getModelActions({
                    id: model.modelBaseId,
                    name: name,
                    application: model.application,
                    sourceModelFileUploadId: model.sourceModelFileUploadId,
                    model: model,
                  })}
                  thumbnail={thumbnails?.find((t) => t.key === id)?.value as string}
                  name={name}
                />
              )
          )}
      </div>
      {showPopup && (
        <EditModelPopup
          id={selectedModelBase.id}
          name={selectedModelBase.name}
          onClose={() => setShowPopup(false)}
          onUpdate={(reponse) => updateCard(reponse)}
        />
      )}
      {showInfoPopup && <InfoModelPopup info={selectedModelInfo} onClose={() => setShowInfoPopup(false)} />}
      {showDeleteConfirmation && (
        <ConfirmationDialog
          title="Delete model"
          message={() => (
            <span>
              Are you sure you want to delete <b>{selectedModelBase.name}</b> ?
            </span>
          )}
          onConfirm={() => {
            deleteModelBaseFunc(selectedModelBase.id, selectedModelBase);
            setShowDeleteConfirmation(false);
          }}
          onCancel={() => setShowDeleteConfirmation(false)}
        />
      )}
    </PageLayout>
  );
};
