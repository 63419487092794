import { CreateMaterialProvider } from './context/CreateMaterialContext';

import { TrblPopup, TrblPopupActions, TrblPopupContent, TrblPopupTitle } from '@/components/Shared/Popup';
import { CreateMaterialActions } from './components/CreateMaterialActions';
import { CreateMaterialTabs } from './components/CreateMaterialTabs';
import { MaterialDetails } from './components/MaterialDetails';

export const CreateMaterial = ({
  setShowPopup,
}: {
  setShowPopup: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}) => {
  return (
    <TrblPopup width={1000} maxheight={'96vh'} minheight={'400px'} aria-labelledby={'Create new material'} open={true}>
      <form style={{ display: 'flex', flexFlow: 'column', overflowX: 'hidden' }}>
        <CreateMaterialProvider>
          <TrblPopupTitle onClose={() => setShowPopup(false)}>{'Create new material'}</TrblPopupTitle>
          <TrblPopupContent>
            <MaterialDetails />
            <CreateMaterialTabs />
          </TrblPopupContent>
          <TrblPopupActions framed>
            <CreateMaterialActions setShowPopup={setShowPopup} />
          </TrblPopupActions>
        </CreateMaterialProvider>
      </form>
    </TrblPopup>
  );
};
